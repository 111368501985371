<template>
    <div class="company__menu">
        <ul v-if="mainMenuOpen" class="company__menu-list">
            <!-- <li class="company__menu-list-element">
                <div class="category-heading">Настройки</div>
                <button @click="fromMainToProfile()" class="category-btn">
                    <img class="category-img" src="./assets/profile.png" alt="Профиль">
                    Профиль
                </button>
            </li>
            <hr> -->
            <li class="company__menu-list-element">
                <div class="category-heading">Компания</div>
                <slot />
            </li>
            <hr>
            <li class="company__menu-list-element">
                <div class="category-heading">Ссылки</div>
                <a target="_blank" href="https://t.me/app_101" class="category-btn">
                    <img class="category-img" src="./assets/telegram.png" alt="Профиль">
                    Мы в Telegram
                    <img class="link-img" src="./assets/link.png" alt="ссылка">
                </a>
                <a target="_blank" href="https://t.me/TechSupport101Bot" class="category-btn">
                    <img class="category-img" src="./assets/support.png" alt="Профиль">
                    Помощь
                    <img class="link-img" src="./assets/link.png" alt="ссылка">
                </a>
            </li>
            <hr>
            <li class="company__menu-list-element">
                <div class="external">
                    <a target="_blank" href="https://101-app.com/faq?utm_source=101app&utm_medium=web&utm_campaign=dashboard#!/tfeeds/229179393101/c/%D0%A0%D1%83%D0%BA%D0%BE%D0%B2%D0%BE%D0%B4%D1%81%D1%82%D0%B2%D0%BE%20%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8F%20%D0%B4%D0%BB%D1%8F%20%D0%97%D0%B0%D0%BA%D0%B0%D0%B7%D1%87%D0%B8%D0%BA%D0%B0" class="external-link">Руководство</a>
                    <a target="_blank" href="https://101-app.com/privacy?utm_source=101app&utm_medium=web_version&utm_campaign=dashboard" class="external-link">Политика конфиденциальности</a>
                    <a target="_blank" href="https://101-app.com/legacy?utm_source=101app&utm_medium=web&utm_campaign=dashboard" class="external-link">Лицензионное соглашение</a>
                    <a target="_blank" href="https://101-app.com/offer?utm_source=101app&utm_medium=web&utm_campaign=dashboard" class="external-link">Договор оферты</a>
                </div>
            </li>
            <hr>
            <button @click="$emit('logout')" class="category-btn">
                <img class="category-img" src="./assets/exit.png" alt="Профиль">
                Выйти из аккаунта
            </button>
        </ul>
        <ul v-if="profileMenuOpen" id="profile-menu" class="company__menu-list">
            <div class="sub-category-heading">
                <button @click="fromProfileToMain" class="go-back-btn">
                    <img src="./assets/arrow_left.png" alt="Назад">
                </button>
                Профиль
            </div>
            <li class="company__menu-list-element">
                <div class="category-buttons">
                    <button @click="fromProfileToParams" class="category-btn">
                        <img class="category-img" src="./assets/profile.png" alt="Профиль">
                        Параметры профиля
                    </button>
                    <button @click="fromProfileToRequisites" class="category-btn">
                        <img class="category-img" src="./assets/requisites.png" alt="Профиль">
                        Реквизиты
                    </button>
                    <button @click="fromProfileToPassword" class="category-btn">
                        <img class="category-img" src="./assets/password.png" alt="Профиль">
                        Изменить пароль
                    </button>
                    <button @click="fromProfileToTrustCode" class="category-btn">
                        <img class="category-img" src="./assets/trust_code.png" alt="Профиль">
                        Доверительный код
                    </button>
                    <button class="category-btn">
                        <img class="category-img" src="./assets/subscription.png" alt="Профиль">
                        <div class="subscription-holder">
                            Подписка
                            <div class="subscription">
                                <div v-if="subscriptionDataLoaded && userSubscriptionData.subscription_is_active && userSubscriptionData.subscription_expiration_date_original !== null">
                                <p class="subscription-until">до {{ formatDate(userSubscriptionData.subscription_expiration_date_original, true) }}</p>
                                </div>
                                <span v-if="subscriptionsLoaded && subscriptionDataLoaded && userSubscriptionData && userSubscriptionData.subscription_type === '1month'" >
                                    <img class="subscription-img" src="./assets/pro.png" alt="Подписка Pro">
                                </span>
                                <span v-if="subscriptionsLoaded && subscriptionDataLoaded && userSubscriptionData && userSubscriptionData.subscription_type === '12month'">
                                    <img class="subscription-img" src="./assets/pro.png" alt="Подписка Pro">
                                </span>
                                <span v-if="subscriptionsLoaded && subscriptionDataLoaded && userSubscriptionData && userSubscriptionData.subscription_type === '1monthplus'">
                                    <img class="subscription-img" src="./assets/pro+.png" alt="Подписка Pro">
                                </span>
                                <span v-if="subscriptionsLoaded && subscriptionDataLoaded && userSubscriptionData && userSubscriptionData.subscription_type === '12monthplus'">
                                    <img class="subscription-img" src="./assets/pro+.png" alt="Подписка Pro">
                                </span>
                                <span v-if="subscriptionsLoaded && subscriptionDataLoaded && userSubscriptionData && userSubscriptionData.subscription_type === '1monthsvoy'">
                                    <img class="subscription-img" src="./assets/club.png" alt="Подписка Pro">
                                </span>
                            </div>
                        </div>
                    </button>
                    <button @click="$emit('logout')" class="category-btn">
                        <img class="category-img" src="./assets/exit.png" alt="Профиль">
                        Выход
                    </button>
                </div>
            </li>
        </ul>
        <ul v-if="profileParamsOpen" id="profile-params" class="company__menu-list">
            <div class="sub-category-heading">
                <button @click="fromParamsToProfile" class="go-back-btn">
                    <img src="./assets/arrow_left.png" alt="Назад">
                </button>
                Параметры профиля
            </div>
            <li class="company__menu-list-element">
                <div class="category-buttons">
                    <div class="avatar">
                        <img src="./assets/avatar.png" alt="Аватар">
                    </div>
                    <form class="personal-info">
                        <div class="element">
                            <label for="fio">ФИО</label>
                            <div class="input-holder">
                                <input name="fio" type="text" placeholder="ФИО">
                            </div>
                        </div>
                        <div class="element">
                            <label for="number">Номер телефона</label>
                            <div class="input-holder">
                                <input name="number" type="text" placeholder="+7 000 000-00-00">
                            </div>
                        </div>
                        <div class="element">
                            <label for="category">Категория</label>
                            <div class="input-holder">
                                <input name="category" type="text" disabled placeholder="Партнёр">
                            </div>
                        </div>
                        <div class="element">
                            <label for="email">Электронная почта</label>
                            <div class="input-holder">
                                <input name="email" type="text" disabled placeholder="test@test.com">
                            </div>
                        </div>
                        <button @click.prevent>Сохранить</button>
                        <button @click.prevent class="transparent">Удалить</button>
                    </form>
                </div>
            </li>
        </ul>
        <ul v-if="changePasswordOpen" id="change-password-menu" class="company__menu-list">
            <div class="sub-category-heading">
                <button @click="fromPasswordToProfile" class="go-back-btn">
                    <img src="./assets/arrow_left.png" alt="Назад">
                </button>
                Изменить пароль
            </div>
            <li class="company__menu-list-element">
                <div class="category-buttons">
                    <div class="side-info">
                        Пароль поменяется для входа в{{'\xa0'}}веб-версию и{{'\xa0'}}приложение. Вы выйдете со{{'\xa0'}}всех устройств, кроме текущего.
                    </div>
                    <form class="password-info">
                        <div class="element">
                            <label for="old-password">Старый пароль</label>
                            <div class="input-holder">
                                <input name="old-password" :type="checkOld ? 'text' : 'password'" @input="oldCheck" v-model="oldPasswordValue" placeholder="Введите ваш старый пароль">
                                <div v-show="oldPasswordValue !== ''" @click="showOld = !showOld" class="show-password">
                                    <img src="./assets/show.png" alt="Показать пароль">
                                </div>
                            </div>
                        </div>
                        <div class="element">
                            <label for="new-password">Новый пароль</label>
                            <div class="input-holder">
                                <input name="new-password" :type="checkFirst ? 'text' : 'password'" @input="newCheck" v-model="passwordValue" placeholder="Ваш новый пароль"
                                :disabled="!checkOldLength">
                                <div v-show="passwordValue !== ''" @click="showFirst = !showFirst" class="show-password">
                                    <img src="./assets/show.png" alt="Показать пароль">
                                </div>
                            </div>
                            <div class="meter-holder">
                                <password-meter :password="passwordValue" @score="onScore" />
                            </div>
                            <div v-if="passwordValue !== ''" class="warning-holder">
                                <span class="warning short" v-if="score === 0 || score === 1">Слишком короткий пароль</span>
                                <span class="warning good" v-if="score === 2 || score === 3">Обычный пароль</span>
                                <span class="warning great" v-if="score === 4">Надежный пароль</span>
                            </div>
                        </div>
                        <div class="element">
                            <label for="repeat-password">Повторите новый пароль</label>
                            <div class="input-holder">
                                <input :disabled="!checkOldLength" name="repeat-password" :type="checkSecond ? 'text' : 'password'" @input="repeatCheck" v-model="repeatPasswordValue" placeholder="Ваш новый пароль">
                                <div v-show="repeatPasswordValue !== ''" @click="showSecond = !showSecond" class="show-password">
                                    <img src="./assets/show.png" alt="Показать пароль">
                                </div>
                            </div>
                            <div v-show="repeatPasswordValue !== ''" class="passwords-compare">
                                <span class="equality warning" v-if="!checkEquality">Пароли не совпадают</span>
                                <span class="equality success" v-if="checkEquality">Пароли совпадают</span>
                            </div>
                        </div>
                        <button :disabled="finalCheck === false" class="save-button" @click.prevent>Сохранить</button>
                    </form>
                </div>
            </li>
        </ul>
        <ul v-if="trustCodeOpen" id="trust-code-menu" class="company__menu-list">
            <div class="sub-category-heading">
                <button @click="fromTrustCodeToProfile" class="go-back-btn">
                    <img src="./assets/arrow_left.png" alt="Назад">
                </button>
                Доверительный код
            </div>
            <li class="company__menu-list-element">
                <div class="category-buttons">
                    <div class="side-info">
                        Доверительный код доступен при наличии подписки <b>Pro</b> и{{'\xa0'}}<b>Pro+</b> даёт возможность другому пользователю создать свою компанию. Поделитесь кодом с{{'\xa0'}}теми, кому доверяете. У{{'\xa0'}}вас{{'\xa0'}}есть всего один доверительный код.
                    </div>
                    <form class="trust-code-info">
                        <div class="element">
                            <div style="margin-top: 5px" class="input-holder">
                                <input :readonly="trustCodeValid" :disabled="!trustCodeValid" name="old-password" type="text" :value="trustCodeValue">
                                <div class="tooltip">Доверительный код скопирован</div>
                                <div v-if="trustCodeValid" @click="copyToClipboard(trustCodeValue)" class="copy">
                                    <img src="./assets/copy.png" alt="Скопировать доверительный код">
                                </div>
                            </div>
                            <div v-if="trustCodeValid" class="caption valid">
                                Код действителен
                            </div>
                            <div v-if="!trustCodeValid" class="caption not-valid">
                                Код использован
                            </div>
                        </div>
                    </form>
                </div>
            </li>
        </ul>
        <ul v-if="requisitesOpen" id="requisites-menu" class="company__menu-list">
            <div class="sub-category-heading">
                <button @click="fromRequisitesToProfile" class="go-back-btn">
                    <img src="./assets/arrow_left.png" alt="Назад">
                </button>
                Реквизиты
                <button v-if="counterChosen === 'Физическое лицо'" @click="copyIndiv" class="copy-btn">
                    <img src="./assets/copy_header.png" alt="Назад">
                </button>
                <button @click="copyCompany" v-if="counterChosen === 'ООО'" class="copy-btn">
                    <img src="./assets/copy_header.png" alt="Назад">
                </button>
                <button @click="copySelfEmployed" v-if="counterChosen === 'Самозанятый'" class="copy-btn">
                    <img src="./assets/copy_header.png" alt="Назад">
                </button>
                <button @click="copyEnterpreneur" v-if="counterChosen === 'ИП'" class="copy-btn">
                    <img src="./assets/copy_header.png" alt="Назад">
                </button>
            </div>
            <div class="tooltip">Реквизиты скопированы</div>
            <form>
                <div style="margin-top: 10px" class="element">
                    <label for="counter-type">Тип контрагента</label>
                    <div class="input-holder">
                        <v-select class="dropdown" name="counter-type" :clearable="false" v-model="counterChosen" :components="{Deselect}" placeholder="Выберите категорию" :searchable="false" value="Физическое лицо" :options="counterList"></v-select>
                        <img @click="openDropdown" class="chevron" src="./assets/chevron_down.png" alt="Шеврон">
                    </div>
                </div>
                <div style="height: 8px"></div>
                <hr>
                <div class="requisites" v-if="counterChosen === 'Физическое лицо'">
                    <div class="heading">Данные контрагента</div>
                    <div class="element">
                        <label for="full-name">ФИО</label>
                        <div class="input-holder">
                            <input v-model="individualData.fullName" name="full-name" type="text" placeholder="ФИО">
                        </div>
                    </div>
                    <div class="element">
                        <label for="birth-date">Дата рождения</label>
                        <div class="input-holder">
                            <date-picker prefix-class="xmx" :input-attr="{id: 'birthDate', name: 'birth-date'}" input-class="date-picker"  :clearable="false" :default="individualData.counterBirthDate" placeholder="дд/мм/гггг" format="DD/MM/YYYY" v-model="individualData.counterBirthDate">
                                <template #icon-calendar>
                                    <div class="calendar">
                                        <img src="./assets/calendar.png" alt="Календарь">
                                    </div>
                                </template>
                            </date-picker>
                        </div>
                    </div>
                    <div class="element">
                        <label for="passport-number">Серия (4 цифры), номер паспорта (6 цифр)</label>
                        <div class="input-holder">
                            <imask-input
                            name="passport-number"
                            v-model="individualData.passportNumber"
                            mask="0000-000000"
                            :unmask="true"
                            placeholder='0000-000000'
                            />
                        </div>
                    </div>
                    <div class="element">
                        <label for="passport-issue-date">Когда выдан</label>
                        <div class="input-holder">
                            <date-picker prefix-class="xmx" :input-attr="{id: 'passport-issue-date', name: 'passport-issue-date'}" input-class="date-picker"  :clearable="false" :default="individualData.passportIssueDate" placeholder="дд/мм/гггг" format="DD/MM/YYYY" v-model="individualData.passportIssueDate">
                                <template #icon-calendar>
                                    <div class="calendar">
                                        <img src="./assets/calendar.png" alt="Календарь">
                                    </div>
                                </template>
                            </date-picker>
                        </div>
                    </div>
                    <div class="element">
                        <label for="issued-by">Кем выдан</label>
                        <div class="input-holder">
                            <input v-model="individualData.passportIssuedBy" name="issued-by" type="text" placeholder="Кем выдан">
                        </div>
                    </div>
                    <div class="element">
                        <label for="depart-number">Код подразделения (6 цифр)</label>
                        <div class="input-holder">
                            <imask-input
                            name="depart-number"
                            v-model="individualData.departNumber"
                            mask="000000"
                            :unmask="true"
                            placeholder='000000'
                            />
                        </div>
                    </div>
                    <div class="element">
                        <label for="registration">Адрес места регистрации</label>
                        <div class="input-holder">
                            <input v-model="individualData.registration" name="registration" type="text" placeholder="Адрес места регистрации">
                        </div>
                    </div>
                    <hr>
                    <div class="heading">Банковские реквизиты</div>
                    <div class="element">
                        <label for="bank-name">Наименование банка</label>
                        <div class="input-holder">
                            <input v-model="individualData.bankName" name="bank-name" type="text" placeholder="Наименование банка">
                        </div>
                    </div>
                    <div class="element">
                        <label for="checking account">Расчетный счёт (20 цифр)</label>
                        <div class="input-holder">
                            <imask-input
                            name="checking account"
                            v-model="individualData.checkingAccount"
                            mask="00000000000000000000"
                            :unmask="true"
                            placeholder='00000000000000000000'
                            />
                        </div>
                    </div>
                    <div class="element">
                        <label for="checking account">БИК (9 цифр)</label>
                        <div class="input-holder">
                            <imask-input
                            name="checking account"
                            v-model="individualData.identCode"
                            mask="000000000"
                            :unmask="true"
                            placeholder='000000000'
                            />
                        </div>
                    </div>
                    <div class="element">
                        <label for="checking account">Корреспондентский счёт (20 цифр)</label>
                        <div class="input-holder">
                            <imask-input
                            name="checking account"
                            v-model="individualData.correspondentAccount"
                            mask="00000000000000000000"
                            :unmask="true"
                            placeholder='00000000000000000000'
                            />
                        </div>
                    </div>
                    <hr>
                    <div class="heading">Данные из профиля</div>
                    <div class="element">
                        <label for="phone-number">Номер телефона</label>
                        <div class="input-holder">
                            <imask-input
                            v-model="individualData.phoneNumber"
                            name="phone-number"
                            mask="+7 000 000-00-00"
                            :unmask="true"
                            placeholder='+7 000 000-00-00'
                            />
                        </div>
                    </div>
                    <div class="element">
                        <label for="email">Электронная почта</label>
                        <div class="input-holder">
                            <input v-model="individualData.email" name="email" type="text" placeholder="Наименование банка">
                        </div>
                    </div>
                    <button class="save-btn" @click.prevent>Сохранить</button>
                </div>
                <div class="requisites" v-if="counterChosen === 'ООО'">
                    <div class="heading">Данные контрагента</div>
                    <div class="element">
                        <div class="input-holder">
                            <img class="search-img" src="./assets/search.png" alt="Поиск">
                            <input class="search" type="text" placeholder="Наименование организации или ИНН">
                        </div>
                    </div>
                    <div class="element">
                        <label for="company-name">Наименование организации</label>
                        <div class="input-holder">
                            <input v-model="companyData.name" name="company-name" type="text" placeholder="Наименование организации">
                        </div>
                    </div>
                    <div class="element">
                        <label for="inn-number">ИНН (10 цифр)</label>
                        <div class="input-holder">
                            <imask-input
                            name="inn-number"
                            v-model="companyData.InnNumber"
                            mask="0000000000"
                            :unmask="true"
                            placeholder='0000000000'
                            />
                        </div>
                    </div>
                    <div class="element">
                        <label for="ogrn-number">ОГРН (13 цифр)</label>
                        <div class="input-holder">
                            <imask-input
                            name="ogrn-number"
                            v-model="companyData.OgrnNumber"
                            mask="0000000000000"
                            :unmask="true"
                            placeholder='0000000000000'
                            />
                        </div>
                    </div>
                    <div class="element">
                        <label for="legal-address">Юридический адрес</label>
                        <div class="input-holder">
                            <input v-model="companyData.legalAddress" name="legal-address" type="text" placeholder="Юридический адрес">
                        </div>
                    </div>
                    <div class="element">
                        <label for="actual-address">Фактический адрес</label>
                        <div class="input-holder">
                            <input v-model="companyData.actualAddress" name="actual-address" type="text" placeholder="Фактический адрес">
                        </div>
                    </div>
                    <hr>
                    <div class="heading">Банковские реквизиты</div>
                    <div class="element">
                        <label for="bank-name">Наименование банка</label>
                        <div class="input-holder">
                            <input v-model="individualData.bankName" name="bank-name" type="text" placeholder="Наименование банка">
                        </div>
                    </div>
                    <div class="element">
                        <label for="checking account">Расчетный счёт (20 цифр)</label>
                        <div class="input-holder">
                            <imask-input
                            name="checking account"
                            v-model="individualData.checkingAccount"
                            mask="00000000000000000000"
                            :unmask="true"
                            placeholder='00000000000000000000'
                            />
                        </div>
                    </div>
                    <div class="element">
                        <label for="ident number">БИК (9 цифр)</label>
                        <div class="input-holder">
                            <imask-input
                            name="ident number"
                            v-model="individualData.identCode"
                            mask="000000000"
                            :unmask="true"
                            placeholder='000000000'
                            />
                        </div>
                    </div>
                    <div class="element">
                        <label for="correspondent-account">Корреспондентский счёт (20 цифр)</label>
                        <div class="input-holder">
                            <imask-input
                            name="correspondent-account"
                            v-model="individualData.correspondentAccount"
                            mask="00000000000000000000"
                            :unmask="true"
                            placeholder='00000000000000000000'
                            />
                        </div>
                    </div>
                    <hr>
                    <div class="heading">Данные из профиля</div>
                    <div class="element">
                        <label for="phone-number">Номер телефона</label>
                        <div class="input-holder">
                            <imask-input
                            v-model="individualData.phoneNumber"
                            name="phone-number"
                            mask="+7 000 000-00-00"
                            :unmask="true"
                            placeholder='+7 000 000-00-00'
                            />
                        </div>
                    </div>
                    <div class="element">
                        <label for="email">Электронная почта</label>
                        <div class="input-holder">
                            <input v-model="individualData.email" name="email" type="text" placeholder="Наименование банка">
                        </div>
                    </div>
                    <button class="save-btn" @click.prevent>Сохранить</button>
                </div>
                <div class="requisites" v-if="counterChosen === 'Самозанятый'">
                    <div class="heading">Данные контрагента</div>
                    <div class="element">
                        <label for="company-name">ФИО</label>
                        <div class="input-holder">
                            <input v-model="selfEmployedData.fullName" name="company-name" type="text" placeholder="ФИО">
                        </div>
                    </div>
                    <div class="element">
                        <label for="inn-number">ИНН (12 цифр)</label>
                        <div class="input-holder">
                            <imask-input
                            name="inn-number"
                            v-model="selfEmployedData.InnNumber"
                            mask="000000000000"
                            :unmask="true"
                            placeholder='000000000000'
                            />
                        </div>
                    </div>
                    <div class="element">
                        <label for="registation">Адрес места регистрации</label>
                        <div class="input-holder">
                            <input v-model="selfEmployedData.registration" name="registation" type="text" placeholder="Адрес места регистрации">
                        </div>
                    </div>
                    <hr>
                    <div class="heading">Банковские реквизиты</div>
                    <div class="element">
                        <label for="bank-name">Наименование банка</label>
                        <div class="input-holder">
                            <input v-model="selfEmployedData.bankName" name="bank-name" type="text" placeholder="Наименование банка">
                        </div>
                    </div>
                    <div class="element">
                        <label for="checking account">Расчетный счёт (20 цифр)</label>
                        <div class="input-holder">
                            <imask-input
                            name="checking account"
                            v-model="selfEmployedData.checkingAccount"
                            mask="00000000000000000000"
                            :unmask="true"
                            placeholder='00000000000000000000'
                            />
                        </div>
                    </div>
                    <div class="element">
                        <label for="ident-number">БИК (9 цифр)</label>
                        <div class="input-holder">
                            <imask-input
                            name="ident-number"
                            v-model="selfEmployedData.identCode"
                            mask="000000000"
                            :unmask="true"
                            placeholder='000000000'
                            />
                        </div>
                    </div>
                    <div class="element">
                        <label for="correspondent-number">Корреспондентский счёт (20 цифр)</label>
                        <div class="input-holder">
                            <imask-input
                            name="correspondent-number"
                            v-model="selfEmployedData.correspondentAccount"
                            mask="00000000000000000000"
                            :unmask="true"
                            placeholder='00000000000000000000'
                            />
                        </div>
                    </div>
                    <hr>
                    <div class="heading">Данные из профиля</div>
                    <div class="element">
                        <label for="phone-number">Номер телефона</label>
                        <div class="input-holder">
                            <imask-input
                            v-model="selfEmployedData.phoneNumber"
                            name="phone-number"
                            mask="+7 000 000-00-00"
                            :unmask="true"
                            placeholder='+7 000 000-00-00'
                            />
                        </div>
                    </div>
                    <div class="element">
                        <label for="email">Электронная почта</label>
                        <div class="input-holder">
                            <input v-model="selfEmployedData.email" name="email" type="text" placeholder="Наименование банка">
                        </div>
                    </div>
                    <button class="save-btn" @click.prevent>Сохранить</button>
                </div>
                <div class="requisites" v-if="counterChosen === 'ИП'">
                    <div class="heading">Данные контрагента</div>
                    <div class="element">
                        <div class="input-holder">
                            <img class="search-img" src="./assets/search.png" alt="Поиск">
                            <input class="search" type="text" placeholder="ФИО или ИНН">
                        </div>
                    </div>
                    <div class="element">
                        <label for="company-name">ФИО</label>
                        <div class="input-holder">
                            <input v-model="enterpreneurData.fullName" name="company-name" type="text" placeholder="ФИО">
                        </div>
                    </div>
                    <div class="element">
                        <label for="inn-number">ИНН (12 цифр)</label>
                        <div class="input-holder">
                            <imask-input
                            name="inn-number"
                            v-model="enterpreneurData.InnNumber"
                            mask="000000000000"
                            :unmask="true"
                            placeholder='000000000000'
                            />
                        </div>
                    </div>
                    <div class="element">
                        <label for="ogrnip-number">ОРНИП (15 цифр)</label>
                        <div class="input-holder">
                            <imask-input
                            name="ogrnip-number"
                            v-model="enterpreneurData.OgrnipNumber"
                            mask="000000000000"
                            :unmask="true"
                            placeholder='000000000000'
                            />
                        </div>
                    </div>
                    <div class="element">
                        <label for="legal-address">Юридический адрес</label>
                        <div class="input-holder">
                            <input v-model="enterpreneurData.legalAddress" name="legal-address" type="text" placeholder="Юридический адрес">
                        </div>
                    </div>
                    <div class="element">
                        <label for="actual-address">Фактический адрес</label>
                        <div class="input-holder">
                            <input v-model="enterpreneurData.actualAddress" name="actual-address" type="text" placeholder="Фактический адрес">
                        </div>
                    </div>
                    <hr>
                    <div class="heading">Банковские реквизиты</div>
                    <div class="element">
                        <label for="bank-name">Наименование банка</label>
                        <div class="input-holder">
                            <input v-model="enterpreneurData.bankName" name="bank-name" type="text" placeholder="Наименование банка">
                        </div>
                    </div>
                    <div class="element">
                        <label for="checking account">Расчетный счёт (20 цифр)</label>
                        <div class="input-holder">
                            <imask-input
                            name="checking account"
                            v-model="enterpreneurData.checkingAccount"
                            mask="00000000000000000000"
                            :unmask="true"
                            placeholder='00000000000000000000'
                            />
                        </div>
                    </div>
                    <div class="element">
                        <label for="ident-number">БИК (9 цифр)</label>
                        <div class="input-holder">
                            <imask-input
                            name="ident-number"
                            v-model="enterpreneurData.identCode"
                            mask="000000000"
                            :unmask="true"
                            placeholder='000000000'
                            />
                        </div>
                    </div>
                    <div class="element">
                        <label for="correspondent-account">Корреспондентский счёт (20 цифр)</label>
                        <div class="input-holder">
                            <imask-input
                            name="correspondent-account"
                            v-model="enterpreneurData.correspondentAccount"
                            mask="00000000000000000000"
                            :unmask="true"
                            placeholder='00000000000000000000'
                            />
                        </div>
                    </div>
                    <hr>
                    <div class="heading">Данные из профиля</div>
                    <div class="element">
                        <label for="phone-number">Номер телефона</label>
                        <div class="input-holder">
                            <imask-input
                            v-model="enterpreneurData.phoneNumber"
                            name="phone-number"
                            mask="+7 000 000-00-00"
                            :unmask="true"
                            placeholder='+7 000 000-00-00'
                            />
                        </div>
                    </div>
                    <div class="element">
                        <label for="email">Электронная почта</label>
                        <div class="input-holder">
                            <input v-model="enterpreneurData.email" name="email" type="text" placeholder="Наименование банка">
                        </div>
                    </div>
                    <button class="save-btn" @click.prevent>Сохранить</button>
                </div>
            </form>
        </ul>
    </div>
</template>

<script>
import getLinks from './links';
import { mapActions, mapState, mapGetters } from 'vuex';
import passwordMeter from 'vue-simple-password-meter';
import 'vue-select/dist/vue-select.css';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ru';
import { IMaskComponent } from 'vue-imask';
import formatDate from '@/utils/crmFormatDate';

export default {
    components: {
        passwordMeter,
        DatePicker,
        'imask-input': IMaskComponent
    },
    props: {
        title: String,
        imgSrc: String,
        toggleList: Function
    },
    data: function () {
        return {
            Deselect: {
            render: createElement => createElement('span', '✕')
            },
            links: [],
            individualData: {
                fullName: 'Иванов Иван Иванович',
                counterBirthDate: new Date(1995, 11, 17),
                passportIssueDate: new Date(2002, 4, 11),
                passportIssuedBy: 'Отделением №2 УФМС Москвы',
                passportNumber: '4817566934',
                departNumber: '310335',
                registration: 'г. Москва',
                bankName: 'Сбер',
                checkingAccount: '48172934674372364234',
                correspondentAccount: '39802354897483948394',
                identCode: '328094387',
                phoneNumber: '9993721182',
                email: 'bochar113@yandex.ru'
            },
            companyData: {
                name: 'ООО Компания',
                InnNumber: '5225323643',
                OgrnNumber: '3103351673542',
                legalAddress: 'г. Москва, юр. адрес',
                actualAddress: 'г. Москва, фактический адрес',
                bankName: 'Сбер',
                checkingAccount: '48172934674372364234',
                correspondentAccount: '39802354897483948394',
                identCode: '328094387',
                phoneNumber: '9993721182',
                email: 'bochar113@yandex.ru'
            },
            selfEmployedData: {
                fullName: 'Иванов Иван Иванович',
                InnNumber: '522532364334',
                registration: 'г. Москва',
                bankName: 'Точка',
                checkingAccount: '48172934674372364234',
                correspondentAccount: '39802354897483948394',
                identCode: '328094387',
                phoneNumber: '9993721182',
                email: 'bochar113@yandex.ru'
            },
            enterpreneurData: {
                fullName: 'Иванов Иван Иванович',
                InnNumber: '522532364334',
                OgrnipNumber: '522532364334644',
                legalAddress: 'г. Москва, юр. адрес',
                actualAddress: 'г. Москва, фактический адрес',
                bankName: 'Точка',
                checkingAccount: '48172934674372364234',
                correspondentAccount: '39802354897483948394',
                identCode: '328094387',
                phoneNumber: '9093721182',
                email: 'bochar113@yandex.ru'
            },
            counterList: ['Физическое лицо', 'ООО', 'Самозанятый', 'ИП'],
            counterChosen: 'ИП',
            showOld: false,
            showFirst: false,
            showSecond: false,
            trustCodeValid: true,
            trustCodeValue: '96WA3PV94',
            oldPasswordValue: '',
            passwordValue: '',
            repeatPasswordValue: '',
            score: null,
            mainMenuOpen: true,
            profileMenuOpen: false,
            profileParamsOpen: false,
            changePasswordOpen: false,
            trustCodeOpen: false,
            requisitesOpen: false
        };
    },
    created () {
        this.FETCH_USERS();
        getLinks().then(data => {
        this.links = data;
        })
    },
    mounted () {
        const date = document.querySelectorAll('.date-picker');
        const currentYear = new Date().getFullYear()

        function checkValue (str, max) {
        if (str.charAt(0) !== '0' || str === '00') {
            var num = parseInt(str);
            if (isNaN(num) || num <= 0 || num > max) num = 1;
            str = num > parseInt(max.toString().charAt(0)) && num.toString().length === 1 ? '0' + num : num.toString();
            };
            return str;
            };
            date.forEach((element) => {
                element.addEventListener('input', function (e) {
            this.type = 'text';
            var input = this.value;
            if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
            var values = input.split('/').map(function (v) {
                return v.replace(/\D/g, '')
            });
            if (values[0]) values[0] = checkValue(values[0], 31);
            if (values[1]) values[1] = checkValue(values[1], 12);
            if (values[2]) values[2] = checkValue(values[2], currentYear);
            var output = values.map(function (v, i) {
                return v.length === 2 && i < 2 ? v + '/' : v;
            });
            this.value = output.join('').substr(0, 14);
            });

            element.addEventListener('blur', function (e) {
            this.type = 'text';
            var input = this.value;
            var values = input.split('/').map(function (v, i) {
                return v.replace(/\D/g, '')
            });
            var output = '';

            if (values.length === 3) {
                var year = parseInt(values[2]);
                var month = parseInt(values[1]);
                var day = parseInt(values[0]);

                const monthIndex = month - 1
                var d = new Date(year, monthIndex, day);
                if (!isNaN(d)) {
                var dates = [d.getDate(), d.getMonth() + 1, d.getFullYear()];
                output = dates.map(function (v) {
                    v = v.toString();
                    return v.length === 1 ? '0' + v : v;
                }).join('/');
                };
            };
            this.value = output;
            });
            })
    },
    computed: {
        ...mapGetters('subscription', {
            proSubscription: 'getProSubscription',
            proPlusSubscription: 'getProPlusSubscription',
            svoySubscription: 'getSvoySubscription',
            activeSubscription: 'getActiveSubscription',
            leftSubscription: 'getLeftSubscription'
        }),
        ...mapState('subscription', {
        subscriptions: state => state.groupedSubscriptions,
        userSubscriptionData: state => state.userSubscriptionData,
        subscriptionsLoaded: state => state.subscriptionsLoaded,
        subscriptionDataLoaded: state => state.subscriptionDataLoaded,
        activePeriod: state => state.activePeriod
        }),
        ...mapState({
        amount: state => {
            return {
            objects: {
                value: state.objects.ids.length || 0,
                isPending: state.objects.isPending
            },
            contractors: {
                value: state.contractors.contractors.ids.length || 0,
                isPending: state.contractors.isPending
            },
            transactions: {
                value: state.transactions.ids.length || 0,
                isPending: state.transactions.isPending
            },
            services: {
                value: (() => {
                const ids = state.services.services.ids;
                const data = state.services.services.data;
                return ids.filter(id => !data[id].is_deleted).length
                })(),
                isPending: state.services.isPending
            },
            crmUser: {
                value: (() => {
                const ids = state.user.users;
                return ids.length
                })(),
                isPending: !state.user.usersLoaded
            }
            }
        }
        }),
        checkOldLength () {
            return this.oldPasswordValue.length > 5
        },
        checkEquality () {
            if (this.passwordValue !== '') {
                return this.passwordValue === this.repeatPasswordValue
            } else return false
        },
        checkScore () {
            return this.score > 1
        },
        finalCheck () {
            if (this.checkEquality === false) return false
            return this.checkEquality === this.checkScore
        },
        checkOld () {
            if (this.oldPasswordValue === '') return false
            else return this.showOld
        },
        checkFirst () {
            if (this.passwordValue === '') return false
            else return this.showFirst
        },
        checkSecond () {
            if (this.repeatPasswordValue === '') return false
            else return this.showSecond
        }
    },
    methods: {
        formatDate: formatDate,
        openDropdown () {
            document.querySelector('.vs__search').focus()
        },
        ...mapActions ('user', [
        'FETCH_USERS'
        ]),
        onScore ({ score }) {
            this.score = score;
        },
        copyToClipboard (value) {
            navigator.clipboard.writeText(value)
            document.querySelector('.trust-code-info .tooltip').classList.add('shown')
            setTimeout(() => {
                document.querySelector('.trust-code-info .tooltip').classList.remove('shown')
            }, 2000)
        },
        fromMainToProfile () {
            this.mainMenuOpen = false
            this.profileMenuOpen = true
        },
        fromProfileToMain () {
            this.profileMenuOpen = false
            this.mainMenuOpen = true
        },
        fromProfileToParams () {
            this.profileMenuOpen = false
            this.profileParamsOpen = true
        },
        fromParamsToProfile () {
            this.profileParamsOpen = false
            this.profileMenuOpen = true
        },
        fromProfileToPassword () {
            this.profileMenuOpen = false
            this.changePasswordOpen = true
        },
        fromPasswordToProfile () {
            this.changePasswordOpen = false
            this.profileMenuOpen = true
        },
        fromProfileToTrustCode () {
            this.profileMenuOpen = false
            this.trustCodeOpen = true
        },
        fromTrustCodeToProfile () {
            this.trustCodeOpen = false
            this.profileMenuOpen = true
        },
        fromProfileToRequisites () {
            this.profileMenuOpen = false
            this.requisitesOpen = true
        },
        fromRequisitesToProfile () {
            this.requisitesOpen = false
            this.profileMenuOpen = true
        },
        oldCheck () {
            if (this.oldPasswordValue === '') {
                this.showOld = false
            }
        },
        newCheck () {
            if (this.passwordValue === '') {
                this.showFirst = false
            }
        },
        repeatCheck () {
            if (this.repeatPasswordValue === '') {
                this.showSecond = false
            }
        },
        copyIndiv () {
            let birthMonth = this.individualData.counterBirthDate.getMonth() + 1
            if (birthMonth < 10) {
                birthMonth = `0${birthMonth.toString()}`
            }

            let passportMonth = this.individualData.passportIssueDate.getMonth() + 1
            if (passportMonth < 10) {
                passportMonth = `0${passportMonth.toString()}`
            }
            const copyData =
(`ФИО: ${this.individualData.fullName}
Дата рождения: ${this.individualData.counterBirthDate.getDate().toString()}/${birthMonth}/${this.individualData.counterBirthDate.getFullYear().toString()}
Серия, номер паспорта: ${this.individualData.passportNumber.slice(0, 4)}-${this.individualData.passportNumber.slice(4, 10)}
Когда выдан: ${this.individualData.passportIssueDate.getDate().toString()}/${passportMonth}/${this.individualData.passportIssueDate.getFullYear().toString()}
Кем выдан: ${this.individualData.passportIssuedBy}
Код подразделения: ${this.individualData.departNumber}
Адрес места регистрации: ${this.individualData.registration}
Наименование банка: ${this.individualData.bankName}
Расчетный счет: ${this.individualData.checkingAccount}
БИК: ${this.individualData.identCode}
Корр. счет: ${this.individualData.correspondentAccount}
Номер телефона: +7 ${this.individualData.phoneNumber.slice(0, 3)} ${this.individualData.phoneNumber.slice(3, 6)}-${this.individualData.phoneNumber.slice(6, 8)}-${this.individualData.phoneNumber.slice(8, 10)}
Электронная почта: ${this.individualData.email}`)

           navigator.clipboard.writeText(copyData)
            document.querySelector('#requisites-menu .tooltip').classList.add('shown')
            setTimeout(() => {
                document.querySelector('#requisites-menu .tooltip').classList.remove('shown')
            }, 2000)
        },
        copyCompany () {
            const copyData =
(`Наименование организации: ${this.companyData.name}
ИНН: ${this.companyData.InnNumber}
ОГРН: ${this.companyData.OgrnNumber}
Юридический адрес: ${this.companyData.legalAddress}
Фактический адрес: ${this.companyData.actualAddress}
Наименование банка: ${this.companyData.bankName}
Расчетный счет: ${this.companyData.checkingAccount}
БИК: ${this.companyData.identCode}
Корр. счет: ${this.companyData.correspondentAccount}
Номер телефона: +7 ${this.companyData.phoneNumber.slice(0, 3)} ${this.companyData.phoneNumber.slice(3, 6)}-${this.companyData.phoneNumber.slice(6, 8)}-${this.companyData.phoneNumber.slice(8, 10)}
Электронная почта: ${this.companyData.email}`)

           navigator.clipboard.writeText(copyData)
            document.querySelector('#requisites-menu .tooltip').classList.add('shown')
            setTimeout(() => {
                document.querySelector('#requisites-menu .tooltip').classList.remove('shown')
            }, 2000)
        },
        copySelfEmployed () {
            const copyData =
(`ФИО: ${this.selfEmployedData.fullName}
ИНН: ${this.selfEmployedData.InnNumber}
Адрес места регистрации: ${this.selfEmployedData.registration}
Наименование банка: ${this.selfEmployedData.bankName}
Расчетный счет: ${this.selfEmployedData.checkingAccount}
БИК: ${this.selfEmployedData.identCode}
Корр. счет: ${this.selfEmployedData.correspondentAccount}
Номер телефона: +7 ${this.selfEmployedData.phoneNumber.slice(0, 3)} ${this.selfEmployedData.phoneNumber.slice(3, 6)}-${this.selfEmployedData.phoneNumber.slice(6, 8)}-${this.selfEmployedData.phoneNumber.slice(8, 10)}
Электронная почта: ${this.selfEmployedData.email}`)

           navigator.clipboard.writeText(copyData)
            document.querySelector('#requisites-menu .tooltip').classList.add('shown')
            setTimeout(() => {
                document.querySelector('#requisites-menu .tooltip').classList.remove('shown')
            }, 2000)
        },
        copyEnterpreneur () {
            const copyData =
(`ФИО: ${this.enterpreneurData.fullName}
ИНН: ${this.enterpreneurData.InnNumber}
ОГРН: ${this.enterpreneurData.OgrnipNumber}
Юридический адрес: ${this.enterpreneurData.legalAddress}
Фактический адрес: ${this.enterpreneurData.actualAddress}
Наименование банка: ${this.enterpreneurData.bankName}
Расчетный счет: ${this.enterpreneurData.checkingAccount}
БИК: ${this.enterpreneurData.identCode}
Корр. счет: ${this.enterpreneurData.correspondentAccount}
Номер телефона: +7 ${this.enterpreneurData.phoneNumber.slice(0, 3)} ${this.enterpreneurData.phoneNumber.slice(3, 6)}-${this.enterpreneurData.phoneNumber.slice(6, 8)}-${this.enterpreneurData.phoneNumber.slice(8, 10)}
Электронная почта: ${this.enterpreneurData.email}`)

           navigator.clipboard.writeText(copyData)
            document.querySelector('#requisites-menu .tooltip').classList.add('shown')
            setTimeout(() => {
                document.querySelector('#requisites-menu .tooltip').classList.remove('shown')
            }, 2000)
        }
    }
}
</script>

<style lang="scss" src="./header-company-menu.scss"></style>
