import { apiClient } from '@/services/apiClient';

export default class CrmUserStatus {
  static async list () {
    let l = {
      1: 'Неквалифицированный',
      2: 'Готов к внедрению',
      3: 'Может отвалиться',
      4: 'Возможно вернется',
      5: 'Неактуально',
      10: 'Активный'
    };
    if ((await apiClient.userPermissions()).data.super_user) {
        l = {
          ...l,
          6: 'Токсичный',
          7: 'Ушел из сферы',
          8: 'Клуб 101',
          9: 'Партнер',
          11: 'Личный бан от Павла',
          12: 'Личный бан от Вадима',
          13: 'Есть другой активный аккаунт',
          14: 'Разрабатывает свой it продукт'
        }
    }

    return l;
  }
}
