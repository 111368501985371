<template>
    <div class="icons">
        <ul class="icons__list">
            <!-- <li id="projects" class="icons__list-element">
                <router-link id="projects-link" :class="{ expanded: menuExpanded }" class="icons__list-link" to="/subscription">
                    <img id="projects-img" :class="{ hidden: menuExpanded }" :src="checkIfPath('/subscription') ? require('./assets/home_active.png') : require('./assets/home.png')" alt="Дом">
                    <img id="projects-img-accent" :class="{ hidden: !menuExpanded }" :src="checkIfPath('/subscription') ? require('./assets/home_active.png') : require('./assets/home.png')" alt="Дом">
                    <div :class="{ hidden: !menuExpanded }" class="icons__list-element-name">Компания</div>
                </router-link>
                <Tooltip id="project-tooltip" name="Компания" />
            </li> -->
            <!-- <li id="employees" class="icons__list-element">
                <router-link :class="{ expanded: menuExpanded }" class="icons__list-link" to="/export">
                    <img :class="{ hidden: menuExpanded }" id="employees-img" :src="checkIfPath('/export') ? require('./assets/employees_accent.png') : require('./assets/employees.png')" alt="Люди">
                    <img :class="{ hidden: !menuExpanded }" id="employees-img-accent" :src="checkIfPath('/export') ? require('./assets/employees_accent.png') : require('./assets/employees.png')" alt="Люди">
                    <div :class="{ hidden: !menuExpanded }" class="icons__list-element-name">Контрагенты</div>
                </router-link>
                <Tooltip id="employees-tooltip" name="Контрагенты" />
            </li> -->
            <li id="price" @click="$emit('menuLinkClicked')" class="icons__list-element">
                <router-link :class="{expanded: menuExpanded}" class="icons__list-link" to="/services">
                    <img id="price-img" :class="{hidden: menuExpanded}" :src="checkIfPath('/services') ? require('./assets/price_active.png') : require('./assets/price.png')" alt="Кисть">
                    <img id="price-img-accent" :class="{ hidden: !menuExpanded }" :src="checkIfPath('/services') ? require('./assets/price_active.png') : require('./assets/price.png')" alt="Кисть">
                    <div :class="{ hidden: !menuExpanded }" class="icons__list-element-name">Прайс&#8209;лист</div>
                </router-link>
                <Tooltip id="price-tooltip" name="Прайс-лист" />
            </li>
            <template v-for="(link, index) in links">
                <li :id="link.id" v-if="link.visible" :key="index" @click="$emit('menuLinkClicked')" class="icons__list-element">
                    <router-link :class="{expanded: menuExpanded}" class="icons__list-link" :to="link.url">
                        <img :id="link.imgId" :class="{hidden: menuExpanded}" :src="checkIfPath(link.url) ? require('./assets/' + link.accentImage) : require('./assets/' + link.mainImage)" :alt="link.name">
                        <img :id="link.accentImgId" :class="{ hidden: !menuExpanded }" :src="checkIfPath(link.url) ? require('./assets/' + link.accentImage) : require('./assets/' + link.mainImage)" :alt="link.name">
                        <div :class="{ hidden: !menuExpanded }" class="icons__list-element-name">{{ link.name }}</div>
                    </router-link>
                    <Tooltip :id="link.tooltipId" :name="link.name" />
                </li>
            </template>
            <!-- <li id="analytics" @click="$emit('menuLinkClicked')" class="icons__list-element">
                <router-link :class="{expanded: menuExpanded}" class="icons__list-link" to="/analytics">
                    <img id="analytics-img" :class="{hidden: menuExpanded}" :src="checkIfPath('/analytics') ? require('./assets/stats_active.png') : require('./assets/stats.png')" alt="Аналитика">
                    <img id="analytics-img-accent" :class="{ hidden: !menuExpanded }" :src="checkIfPath('/analytics') ? require('./assets/stats_active.png') : require('./assets/stats.png')" alt="Аналитика">
                    <div :class="{ hidden: !menuExpanded }" class="icons__list-element-name">Аналитика</div>
                </router-link>
                <Tooltip id="analytics-tooltip" name="Аналитика" />
            </li> -->
            <!-- <li id="users" @click="$emit('menuLinkClicked')" class="icons__list-element">
                <router-link :class="{expanded: menuExpanded}" class="icons__list-link" to="/crm/user">
                    <img id="users-img" :class="{hidden: menuExpanded}" :src="checkIfPath('/crm/user') ? require('./assets/users_active.png') : require('./assets/users.png')" alt="Пользователи">
                    <img id="users-img-accent" :class="{ hidden: !menuExpanded }" :src="checkIfPath('/crm/user') ? require('./assets/users_active.png') : require('./assets/users.png')" alt="Пользователи">
                    <div :class="{ hidden: !menuExpanded }" class="icons__list-element-name">Пользователи</div>
                </router-link>
                <Tooltip id="users-tooltip" name="Пользователи" />
            </li> -->
            <!-- <li id="codes" @click="$emit('menuLinkClicked')" class="icons__list-element">
                <router-link :class="{expanded: menuExpanded}" class="icons__list-link" to="/crm/code">
                    <img id="codes-img" :class="{hidden: menuExpanded}" :src="checkIfPath('/crm/code') ? require('./assets/hash_active.png') : require('./assets/hash.png')" alt="Коды">
                    <img id="codes-img-accent" :class="{ hidden: !menuExpanded }" :src="checkIfPath('/crm/code') ? require('./assets/hash_active.png') : require('./assets/hash.png')" alt="Коды">
                    <div :class="{ hidden: !menuExpanded }" class="icons__list-element-name">Коды</div>
                </router-link>
                <Tooltip id="codes-tooltip" name="Коды" />
            </li> -->
            <!-- <li id="sales" @click="$emit('menuLinkClicked')" class="icons__list-element">
                <router-link :class="{expanded: menuExpanded}" class="icons__list-link" to="/crm/profit">
                    <img id="sales-img" :class="{hidden: menuExpanded}" :src="checkIfPath('/crm/profit') ? require('./assets/sales_active.png') : require('./assets/sales.png')" alt="Продажи">
                    <img id="sales-img-accent" :class="{ hidden: !menuExpanded }" :src="checkIfPath('/crm/profit') ? require('./assets/sales_active.png') : require('./assets/sales.png')" alt="Продажи">
                    <div :class="{ hidden: !menuExpanded }" class="icons__list-element-name">Продажи</div>
                </router-link>
                <Tooltip id="sales-tooltip" name="Продажи" />
            </li> -->
            <!-- <hr> -->
            <!-- <li id="subscription-li" @click="$emit('menuLinkClicked')" class="icons__list-element">
                <router-link :class="{expanded: menuExpanded}" class="icons__list-link" to="/subscription">
                    <img id="subscription" :class="{hidden: menuExpanded}" :src="checkIfPath('/subscription') ? require('./assets/subscription_active.png') : require('./assets/subscription.png')" alt="Продажи">
                    <img id="subscription-accent" :class="{ hidden: !menuExpanded }" :src="checkIfPath('/subscription') ? require('./assets/subscription_active.png') : require('./assets/subscription.png')" alt="Продажи">
                    <div :class="{ hidden: !menuExpanded }" class="icons__list-element-name">Подписка</div>
                </router-link>
            </li> -->
            <hr class="hidden">
            <hr class="expanded">
            <li id="subscription" @click="$emit('menuLinkClicked')" class="icons__list-element">
                <router-link :class="{expanded: menuExpanded}" class="icons__list-link" to="/subscription">
                    <img id="subscription-img" :class="{hidden: menuExpanded}" :src="checkIfPath('/subscription') ? require('./assets/subscription_active.png') : require('./assets/subscription.png')" alt="Подписка">
                    <img id="subscription-img-accent" :class="{ hidden: !menuExpanded }" :src="checkIfPath('/subscription') ? require('./assets/subscription_active.png') : require('./assets/subscription.png')" alt="Подписка">
                    <div :class="{ hidden: !menuExpanded }" class="icons__list-element-name">Подписка</div>
                </router-link>
                <Tooltip id="subscription-tooltip" name="Подписка" />
            </li>
            <li id="buyprice" @click="$emit('menuLinkClicked')" class="icons__list-element">
                <router-link :class="{expanded: menuExpanded}" class="icons__list-link" to="/price">
                    <img id="buyprice-img" :class="{hidden: menuExpanded}" :src="checkIfPath('/price') ? require('./assets/price101_accent.png') : require('./assets/price101.png')" alt="Прайс-лист от 101">
                    <img id="buyprice-img-accent" :class="{ hidden: !menuExpanded }" :src="checkIfPath('/price') ? require('./assets/price101_accent.png') : require('./assets/price101.png')" alt="Прайс-лист от 101">
                    <div :class="{ hidden: !menuExpanded }" class="icons__list-element-name">Прайс-лист от 101</div>
                </router-link>
                <Tooltip id="buyprice-tooltip" name="Прайс-лист от 101" />
            </li>
        </ul>
    </div>
</template>

<script>
import Tooltip from './components/Tooltip.vue'
import getLinks from './links';
import { mapActions, mapState } from 'vuex';

export default {
    data () {
        return {
            links: []
        }
    },
    created () {
        this.FETCH_USERS();
        getLinks().then(data => {
        this.links = data;
        })
    },
    computed: {
        ...mapState({
        amount: state => {
            return {
            objects: {
                value: state.objects.ids.length || 0,
                isPending: state.objects.isPending
            },
            contractors: {
                value: state.contractors.contractors.ids.length || 0,
                isPending: state.contractors.isPending
            },
            transactions: {
                value: state.transactions.ids.length || 0,
                isPending: state.transactions.isPending
            },
            services: {
                value: (() => {
                const ids = state.services.services.ids;
                const data = state.services.services.data;
                return ids.filter(id => !data[id].is_deleted).length
                })(),
                isPending: state.services.isPending
            },
            crmUser: {
                value: (() => {
                const ids = state.user.users;
                return ids.length
                })(),
                isPending: !state.user.usersLoaded
            }
            }
        }
        })
    },
    components: {
        Tooltip
    },
    props: {
        menuExpanded: Boolean
    },
    methods: {
        toggleMenu () {
            this.menuExpanded = !this.menuExpanded
        },
        checkIfPath (path) {
            return (this.$route.path === path)
        },
        ...mapActions('user', [
        'FETCH_USERS'
        ])
    }
}
</script>

<style lang="scss" src="./side-menu-icons.scss"></style>
