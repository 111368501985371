<template>
  <input class="form-input"
         v-bind:value="value"
         v-on:input="$emit('input', $event.target.value)" />
</template>

<script>
export default {
  props: ['value'],
  emits: ['input']
}
</script>

<style scoped lang="scss">
  .form-input {
    width: 100%;
    height: 48px;
    margin: 0;
    box-sizing: border-box;
    padding: 13px 20px;
    font-size: 16px;
    font-family: inherit;
    color: #666666;
    border-radius: 8px;
    border: 1px solid #EBEBEB;
    outline: none;
  }

  .form-input:focus {
    border: 1px solid #8A8A8F;
  }

  /* .form-input:invalid {
      background-color: #FFEEEE;
      border: 1px solid transparent;
  }

  .form-input:valid {
      border: 1px solid #02A21C;
  } */

  @media screen and (max-width: 1200px) {
    .form-input {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 640px) {
    .form-input {
      padding: 16px;
    }
  }
</style>
