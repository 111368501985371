import { apiClient } from '@/services/apiClient';
import toObject from '@/store/utils/toObject';

export default {
  namespaced: true,

  state: {
    contractors: {
      data: {},
      ids: []
    },
    categories: {
      data: {},
      ids: []
    },
    searchInput: '',
    isPending: false
  },

  getters: {
    getCategories: state => {
      const { searchInput, contractors, categories } = state;

      if (contractors.ids) {
        let result = [...contractors.ids];
        if (searchInput) {
          result = contractors.ids.filter(id => contractors.data[id].title.toLowerCase().includes(searchInput.toLowerCase()))
        }

        return categories.ids.map(categoryId => {
          return {
            ...categories.data[categoryId],
            contractorsIds: result.filter(id => contractors.data[id].category === categoryId)
          }
        })
      } else {
        return []
      }
    },

    getContractor: state => id => {
      return state.contractors.data[id];
    },

    getIsCompany: ({ contractors, categories }) => id => {
      const contractorCategoryId = contractors.data[id] ? contractors.data[id].category : null;
      if (contractorCategoryId) {
        const isCompany = categories.data[contractorCategoryId].contractor_type === 5;
        return isCompany;
      }
      return false
    },

    getIsContractor: (state, getters) => {
      // Сотрудник, поставщик, подрядчик
      return getters.getIsRole([1, 2]);
    },

    getIsClient: (state, getters) => {
      // Заказчик
      return getters.getIsRole([4]);
    },

    getIsPartner: (state, getters) => {
      // Партнер, администратор, создатель
      return getters.getIsRole([3, 6]);
    },

    getIsRole: ({ contractors, categories }, getters, rootState, rootGetters) => (types) => {
      const contractorId = rootGetters['companies/getCurrentUserId'];
      const contractorCategoryId = contractors.data[contractorId]?.category;
      return contractorCategoryId ? types.includes(categories.data[contractorCategoryId]?.contractor_type) : false;
    }
  },

  actions: {
    FETCH_ALL: ({ dispatch }) => {
      return Promise.all([
        dispatch('FETCH_CONTRACTORS'),
        dispatch('FETCH_CATEGORIES')
      ])
    },

    FETCH_CONTRACTORS: ({ commit, dispatch, rootState }) => {
      return new Promise((resolve, reject) => { // The Promise used for router redirect in login
        const companyId = rootState.companies.selectedId;
        commit('SET_PENDING', true);
        apiClient.fetchContractors(companyId).then(resp => {
          const items = resp.data;
          commit('SET_CONTRACTORS', toObject(items, 'guid'));
        }).catch(err => {
          reject(err)
        }).then(() => commit('SET_PENDING', false))
      })
    },

    FETCH_CATEGORIES: ({ commit, dispatch, rootState }) => {
      return new Promise((resolve, reject) => { // The Promise used for router redirect in login
        const companyId = rootState.companies.selectedId;
        apiClient.fetchContractorsCategories(companyId).then(resp => {
          const items = resp.data;
          commit('SET_CONTRACTORS_CATEGORIES', toObject(items, 'guid'))
        }).catch(err => {
          reject(err)
        })
      })
    },

    SEARCH_CONTRACTORS: ({ commit }, input) => {
      commit('SET_SEARCH', input);
    }
  },

  mutations: {
    SET_CONTRACTORS: (state, items) => {
      state.contractors = items
    },

    SET_SEARCH: (state, input) => {
      state.searchInput = input;
    },

    SET_CONTRACTORS_CATEGORIES: (state, items) => {
      state.categories = items
    },

    SET_PENDING: (state, isPending) => {
      state.isPending = isPending
    }
  }
}
