import Vue from 'vue';
import Vuex from 'vuex';

import common from './common';
import analytics from './analytics';
import auth from './auth';
import companies from './companies';
import objects from './objects';
import contractors from './contractors';
import transactions from './transactions';
import services from './services';
import subscription from './subscription';
import user from './user';

Vue.use(Vuex)

function defaultState () {
  return {
    analytics,
    auth,
    companies,
    objects,
    contractors,
    common,
    transactions,
    services,
    subscription,
    user
  }
}

export default new Vuex.Store({
  modules: defaultState()
})
