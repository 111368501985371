<template>
    <div class="side-tooltip">
        <div class="side-tooltip__inner">{{ name }}</div>
        <img class="side-tooltip__pointer" src="./assets/pointer.png" alt="">
    </div>
</template>

<script>
export default {
    props: {
        name: String
    }
}
</script>

<style lang="scss" scoped>
.side-tooltip {
    position: absolute;
    background-color: $grayscale-80-color;
    border-radius: 10px;
    color: $text-icons-white-color;
    font-size: $s10-medium-14px-fs;
    font-weight: $s10-medium-14px-fw;
    line-height: $s10-medium-14px-lh;
    padding: 12px;
    left: 55px;
    white-space: nowrap;
    top: 3px;
    visibility: hidden;
    transition: visibility 0.2s, opacity 0.2s;
    opacity: 0;

    &__inner {
        position: relative;
    }

    &__pointer {
        width: 9px;
        height: 15px;
        display: block;
        position: absolute;
        left: -8.5px;
        top: 14.5px;
    }
}
</style>
