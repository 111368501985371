<template>
  <div class="company">
    <div class="company__logo">
      <div class="company__logo-holder">
        <CompanyLogo
          :imgSrc="imgSrc"
        />
      </div>

      <slot />
    </div>

    <div v-if="title" class="company__title">
      {{ title }}
    </div>
  </div>
</template>

<script>
import CompanyLogo from '@/components/CompanyLogo/CompanyLogo'

export default {
  props: {
    title: String,
    imgSrc: String
  },

  components: {
    CompanyLogo
  }
}
</script>

<style src="./company.scss" lang="scss"></style>
