import { apiClient } from '@/services/apiClient';
import Vue from 'vue';

const createDefaultState = () => ({
  subscriptions: [],
  groupedSubscriptions: [],
  requisites: {},
  priceProductData: null,
  userSubscriptionData: null,
  subscriptionsLoaded: false,
  subscriptionDataLoaded: false,
  requisitesLoaded: false,
  activePeriod: 1
})

export default {
  namespaced: true,

  state: createDefaultState(),

  getters: {
    getActiveSubscription: state => {
      let subscription = null
      state.subscriptions.forEach(el => {
        if (el.is_active) {
          subscription = el
        }
      })

      return subscription
    },
    getLeftSubscription: state => {
      let subscription = null
      state.subscriptions.forEach(el => {
        if (el.has_remaining_subscription_period) {
          subscription = el
        }
      })

      return subscription
    },
    getProSubscription: state => {
      return state.groupedSubscriptions[state.activePeriod].pro
    },
    getProPlusSubscription: state => {
      return state.groupedSubscriptions[state.activePeriod].pro_plus
    },
    getSvoySubscription: state => {
      return state.groupedSubscriptions[1].svoy
    },
    getPriceDownloadToken: state => {
      if (state.userSubscriptionData === null) {
        return null
      }

      return state.userSubscriptionData.price_download_token
    },
    getPriceProductData: state => {
      return state.priceProductData
    },
    hasAnyActiveSubscription: state => {
      if (state.userSubscriptionData === null) {
        return false
      }

      return state.userSubscriptionData.is_active
    }
  },

  actions: {
    FETCH_SUBSCRIPTIONS: ({ commit }) => {
      return new Promise((resolve, reject) => {
        apiClient.userSubscriptions().then(resp => {
          commit('SET_SUBSCRIPTIONS', resp.data);
          commit('SET_GROUPED_SUBSCRIPTIONS', resp.data);
        }).catch(err => {
          reject(err)
        }).then(() => commit('SET_SUBSCRIPTIONS_LOADED'))
      })
    },
    FETCH_USER_SUBSCRIPTION_DATA: ({ commit }) => {
      return new Promise((resolve, reject) => {
        apiClient.userSubscriptionInfo().then(resp => {
          commit('SET_USER_SUBSCRIPTION_DATA', resp.data);
        }).catch(err => {
          reject(err)
        }).then(() => commit('SET_SUBSCRIPTION_DATA_LOADED'))
      })
    },
    FETCH_USER_REQUISITES_DATA: ({ commit }) => {
      return new Promise((resolve, reject) => {
        apiClient.userRequisites().then(resp => {
          commit('SET_USER_REQUISITES_DATA', resp.data);
        }).catch(err => {
          reject(err)
        }).then(() => commit('SET_REQUISITES_DATA_LOADED'))
      })
    },
    FETCH_PRICE_PRODUCT_DATA: ({ commit }) => {
      return new Promise((resolve, reject) => {
        apiClient.priceProduct().then(resp => {
          commit('SET_PRICE_PRODUCT_DATA', resp.data);
        }).catch(err => {
          reject(err)
        })
      })
    },
    CREATE_SVOY_REQUEST: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        apiClient.createSvoyRequest(payload).then(resp => {
          resolve(resp)
        }).catch(err => {
          reject(err)
        })
      })
    }
  },

  mutations: {
    SET_SUBSCRIPTIONS: (state, items) => {
      state.subscriptions = items;
    },
    SET_GROUPED_SUBSCRIPTIONS: (state, items) => {
      items.forEach(el => {
        if (el.is_active) {
          state.activePeriod = el.period
        }
        if (state.groupedSubscriptions[el.period] === undefined) {
          state.groupedSubscriptions[el.period] = {}
        }

        const data = state.groupedSubscriptions[el.period]
        data[el.subscription_type] = el
        Vue.set(state.groupedSubscriptions, el.period, data)
      })
    },
    SET_ACTIVE_PERIOD: (state, period) => {
      state.activePeriod = period
    },
    SET_USER_SUBSCRIPTION_DATA: (state, data) => {
      state.userSubscriptionData = data;
    },
    SET_USER_REQUISITES_DATA: (state, data) => {
      state.requisites = data;
    },
    SET_PRICE_PRODUCT_DATA: (state, data) => {
      state.priceProductData = data;
    },
    SET_SUBSCRIPTIONS_LOADED: (state) => {
      state.subscriptionsLoaded = true
    },
    SET_SUBSCRIPTION_DATA_LOADED: (state) => {
      state.subscriptionDataLoaded = true
    },
    SET_REQUISITES_DATA_LOADED: (state) => {
      state.requisitesLoaded = true
    }
  }
}
