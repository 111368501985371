<template>
  <div class="login-page">
    <LoginContainer>
      <LogoNew />
      <LoginForm />
    </LoginContainer>
    <div class="info-container">
      <div class="info">
        <div class="info-text-container">
          <p class="info-text desktop">
            WEB-версия позволяет
            <b
            >основателю компании редактировать прайс-листы и&nbsp;управлять
              подпиской,</b
            >
            остальные пользователи приложения смогут просмотреть и&nbsp;скачать
            pdf-формат прайс-листов.
          </p>
          <p class="info-text mobile">
            WEB-версия позволяет основателю компании редактировать прайс-листы
            и&nbsp;управлять подпиской, остальные пользователи приложения смогут
            просмотреть и&nbsp;скачать pdf-формат прайс-листов.
          </p>
          <p class="info-text low">
            Вход только для зарегистрированных пользователей в приложении.<br />
            <a
              class="instruction-link"
              href="https://101-app.com/instruction-for-install"
            >Инструкция по&nbsp;установке.</a
            >
          </p>
          <p class="info-text"></p>
        </div>
        <div class="img-container">
          <a
            class="info-img-link"
            href="https://apps.apple.com/app/apple-store/id1455342906?pt=117764424&ct=site&mt=8"
          ><img class="info-img" :src="require('./assets/app-store.png')" alt="" /></a
          ><a
          class="info-img-link"
          href="https://play.google.com/store/apps/details?id=ru.group101"
        >
          <img class="info-img middle" :src="require('./assets/google-play.png')" alt="" /> </a
        ><a
          class="info-img-link"
          href="https://drive.google.com/uc?export=download&confirm=no_antivirus&id=1_8t8xwqoSDvvlcqmpoyzYgK0Y2w-FhSD"
        ><img class="info-img" :src="require('./assets/app-gallery.png')" alt=""
        /></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from '@/components/LoginForm/LoginForm';
import LogoNew from '@/components/LogoNew/LogoNew.vue';
import LoginContainer from '@/containers/LoginContainer/LoginContainer.vue';

export default {
  components: {
    LoginContainer,
    LogoNew,
    LoginForm
  }
}
</script>

<style lang="scss" src="./login-page.scss"></style>
