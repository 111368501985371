export default {
  namespaced: true,

  state: {
    isMenu: false
  },

  actions: {
    toggleMenu ({ commit }) {
      commit('SET_MENU')
    }
  },

  mutations: {
    SET_MENU: (state) => {
      state.isMenu = !state.isMenu
    }
  }
}
