<template>
  <div
    class="companies"
    :class="{ 'companies_open': isListOpen }"
  >
    <div class="companies__current">
      <div class="companies__holder">
        <Company
          :title="selectedId ? companies[selectedId].title : null"
          :imgSrc="selectedId ? companies[selectedId].logo : null"
        >
          <button
            class="companies__open"
            @click.self="toggleList"
            @blur="isListOpen && toggleList()"
          >
            <ul v-show="isListOpen" class="companies__list">
              <li
                class="companies__item"
                :class="{ 'companies__item_selected': selectedId === item }"
                v-for="item in companiesIds"
                :key="item"
                :selected="selectedId === item"
                @click="handleSelect(item)"
              >
                <div class="companies__logo">
                  <CompanyLogo :imgSrc="companies[item].logo_url ? companies[item].logo_url : null" small />
                </div>

                <div class="companies__title">
                  {{ companies[item].title }}
                </div>
              </li>

              <li
                class="companies__item"
                key="exit"
                @click.stop.prevent="logout()"
              >
                Выход
              </li>
            </ul>
          </button>
        </Company>
      </div>
    </div>

    <select
      v-show="false"
      class="companies__select"
      :value="selectedId"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Company from '@/components/Company/Company';
import CompanyLogo from '@/components/CompanyLogo/CompanyLogo'

export default {
  data () {
    return {
      isListOpen: false
    }
  },

  computed: {
    ...mapState('companies', {
      companiesIds: state => state.ids,
      companies: state => state.data,
      selectedId: state => state.selectedId
    })
  },

  components: {
    Company, CompanyLogo
  },

  methods: {
    ...mapActions('auth', [
      'AUTH_LOGOUT'
    ]),
    ...mapActions('companies', [
      'SELECT_COMPANY'
    ]),

    logout: function () {
      this.AUTH_LOGOUT()
      .then(() => {
        this.$router.push('/login')
      })
    },

    handleSelect: function (id) {
      if (id !== this.selectedId) {
        this.SELECT_COMPANY(id);
      }

      this.toggleList();
    },

    toggleList: function () {
      this.isListOpen = !this.isListOpen
    }
  }
}
</script>

<style lang="scss" src="./companies.scss"></style>
