<template>
    <div :class="{ expanded: menuExpanded }" class="side-menu">
        <div class="side-menu__upper">
            <div class="side-menu__mobile">
                <button class="side-menu__mobile-btn" @click="toggleMenu">
                    <img src="./assets/hamburger.png" alt="Гамбургер">
                </button>
            </div>
            <div class="side-menu__head">
                <div class="side-menu__heading">Разделы</div>
                <button @click="toggleMenu" class="side-menu__expand-btn">
                    <img src="./assets/chevron.png" alt="Шеврон">
                </button>
            </div>
            <div class="side-menu__icons">
                <SideMenuIcons @menuLinkClicked="$emit('linkClicked')" :menuExpanded="menuExpanded" />
            </div>
        </div>
    </div>
</template>

<script>
import SideMenuIcons from '@/components/SideMenuIcons/SideMenuIcons';

export default {
    components: {
        SideMenuIcons
    },
    data () {
        return {
            menuExpanded: false
        }
    },
    methods: {
        toggleMenu () {
            this.menuExpanded = !this.menuExpanded
            this.$emit('menuExpanded', this.menuExpanded)
        },
        hideOnLinkClick () {
            if (this.menuExpanded) {
                this.menuExpanded = !this.menuExpanded
            }
        }
    }
}
</script>

<style lang="scss" src="./side-menu.scss"></style>
