<template>
  <div v-if="canView()" class="goto-buy-price" @click="$router.push('/price')">
    <div class="goto-buy-price__text">
      Прайс-лист от 101
    </div>
    <img class="goto-buy-price__icon" :src="require('./assets/plus.png')" alt="">
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  props: {
    isAuthenticated: Boolean
  },
  created () {
    if (this.isAuthenticated) {
      this.FETCH_SUBSCRIPTIONS()
      this.FETCH_USER_SUBSCRIPTION_DATA()
    }
  },
  computed: {
    ...mapState({
      subscriptionsLoaded: state => state.subscription.subscriptionsLoaded,
      subscriptionDataLoaded: state => state.subscription.subscriptionDataLoaded
    }),
    ...mapGetters('subscription', {
      subscription: 'getActiveSubscription',
      priceDownloadToken: 'getPriceDownloadToken'
    })
  },
  methods: {
    ...mapActions('subscription', [
      'FETCH_SUBSCRIPTIONS',
      'FETCH_USER_SUBSCRIPTION_DATA'
    ]),
    canView: function () {
      return this.isAuthenticated &&
        this.$route.name !== 'price' &&
        this.subscriptionsLoaded &&
        this.subscription !== null &&
        this.subscriptionDataLoaded &&
        this.priceDownloadToken === null
    }
  }
}
</script>

<style scoped lang="scss">
  .goto-buy-price {
    width: 222px;
    height: 46px;
    margin: 7px 10px 0 0;
    padding: 0 10px 0 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
    background: linear-gradient(260.46deg, #59A9FF 12.45%, #196EC9 75.07%);
    font-family: $montserrat;
    font-style: normal;
    color: #fff;
    text-decoration: none;
    cursor: pointer;

    @media (min-width: $tablet) {
      display: none;
    }

    &__text {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
    }

    &__icon {
      width: 19px;
      height: 19px;
    }
  }
</style>
