import { apiClient } from '@/services/apiClient';
import toObject from '@/store/utils/toObject';
import transactionsTypeNames from '@/services/transactionsTypeNames';

export default {
  namespaced: true,

  state: {
    data: {},
    ids: [],
    searchInput: '',
    isPending: false,
    offset: 0,
    listBy: 100
  },

  getters: {
    getFilteredTransactionsIds: state => {
      const { searchInput, data, ids, offset } = state;
      let result = ids;
      if (searchInput) {
        result = ids.filter(id => data[id].description.toLowerCase().includes(searchInput.toLowerCase()));
      }

      result = result.filter(id => !data[id].is_deleted).sort((a, b) => data[a].date - data[b].date);
      result.length = offset;
      return result.filter(Boolean);
    },

    getFilteredTransactionsIdsByContractor: state => id => {
      const { ids, data } = state;
      return ids.filter(x => data[x].contractor && data[x].contractor === id);
    },

    getFilteredTransactionsIdsByObject: state => id => {
      const { ids, data } = state;
      return ids.filter(x => data[x].stroiobject && data[x].stroiobject === id);
    }
  },

  actions: {
    FETCH_TRANSACTIONS: ({ commit, dispatch, rootState }) => {
      return new Promise((resolve, reject) => {
        const companyId = rootState.companies.selectedId;
        if (companyId) {
          commit('SET_PENDING', true);
          apiClient.fetchTransactions(companyId)
            .then(resp => {
              const result = resp.reduce((acc, response, index) => {
                return [
                  ...acc,
                  ...response.data.map(item => {
                    const transaction = Array.isArray(item) ? item[0] : item;
                    transaction.type = transactionsTypeNames[index];
                    return transaction;
                  })
                ]
              }, []);
              commit('SET_TRANSACTIONS', toObject(result, 'guid'));
              dispatch('LOAD_MORE');
              resolve(resp)
            })
          .catch(err => {
            reject(err)
          }).then(() => commit('SET_PENDING', false))
        } else {
          resolve()
        }
      })
    },

    SEARCH_TRANSACTIONS: ({ commit }, input) => {
      commit('SET_SEARCH', input);
    },

    LOAD_MORE: ({ state, commit }) => {
      const { offset, listBy, ids } = state;
      const newAmount = offset + listBy;
      if (ids.length >= newAmount) {
        commit('SET_OFFSET', newAmount);
      } else {
        commit('SET_OFFSET', ids.length);
      }
    }
  },

  mutations: {
    SET_TRANSACTIONS: (state, { data, ids }) => {
      state.data = data;
      state.ids = ids;
    },

    SET_SEARCH: (state, input) => {
      state.searchInput = input;
    },

    SET_PENDING: (state, isPending) => {
      state.isPending = isPending
    },

    SET_OFFSET: (state, value) => {
      state.offset = value
    }
  }
}
