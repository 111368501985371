<template>
  <modal
    class="modal-header"
    :class="{ [`modal_${theme}`]: theme }"
    height="auto"
    :adaptive="true"
    :maxWidth=" width || 524"
    :name="name"
    styles="border-radius: 8px;"
    @before-close="$emit('onClose')"
    @before-open="setParams"
    @opened="$emit('onOpen')"
  >
      <header v-if="title" class="modal__header">
        <div>
          <div class="modal__title">
            {{ title }}
          </div>

          <div v-if="subtitle" class="modal__subtitle">
            {{ subtitle }}
          </div>
        </div>

        <button class="modal__close" @click="hide"></button>
      </header>

      <main class="modal-header__main">
        <slot />
      </main>
    </modal>
</template>

<script>
export default {
  props: {
    name: String,
    title: String,
    subtitle: String,
    theme: String,
    width: Number
  },

  methods: {
    setParams: function (modal) {
      this.$emit('onSetParams', modal.params)
    },
    hide () {
      this.$modal.hide(this.name);
    }
  }
}
</script>

<style lang="scss">
  .modal-header {
    border-radius: 4px;
    $root: &;

    &__header {
      min-height: 64px;
      background-color: #2C2C2D;
      color: white;
      font-family: $montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 21px;
      line-height: 1.28;
      letter-spacing: -0.34px;
      padding: 18px 24px;
      display: flex;
      justify-content: space-between;

      #{$root}_green & {
        background: #107C41;
      }
    }

    &__close {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      border: none;
      background: rgba(0, 0, 0, 0.38);
      outline: none;
      cursor: pointer;
      position: relative;

      &:before, &:after {
        position: absolute;
        left: 50%;
        top: 50%;
        content: ' ';
        height: 17px;
        width: 2px;
        background-color: #F9FAFA;
      }

      &:before {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }

    &__subtitle {
      font-family: $montserrat;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 1.38;
      color: #8A8A8F;
    }
  }
</style>
