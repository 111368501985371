export default function formatDate (date, asDate = false) {
  if (date === null) {
    return 'не задано'
  }

  const dt = new Date(date)

  if (dt.getFullYear() === 2200) {
    return 'бесконечная'
  }

  if (asDate) {
    return dt.toLocaleDateString('ru-RU')
  }

  return dt.toLocaleString('ru-RU')
}
