import { apiClient } from '@/services/apiClient';

export default async function getLinks () {
  return [
    // {
    //   id: 'objects',
    //   name: 'Объекты',
    //   url: '/objects'
    // },
    // {
    //   id: 'transactions',
    //   name: 'События',
    //   url: '/transactions'
    // },
    // {
    //   id: 'contractors',
    //   name: 'Контрагенты',
    //   url: '/contractors'
    // },
    // {
    //   id: 'subscriptions',
    //   name: 'Подписка',
    //   url: '/subscription',
    //   visible: true
    // },
    // {
    //   id: 'price',
    //   name: 'Прайс-лист от 101',
    //   url: '/price',
    //   visible: true
    // },
    {
      id: 'analytics',
      name: 'Аналитика',
      url: '/analytics',
      imgId: 'analytics-img',
      accentImgId: 'analytics-img-accent',
      mainImage: 'stats.png',
      accentImage: 'stats_active.png',
      tooltipId: 'analytics-tooltip',
      visible: (await apiClient.userPermissions()).data.analytics_access
    },
    {
      id: 'users',
      name: 'Пользователи',
      url: '/crm/user',
      imgId: 'users-img',
      accentImgId: 'users-img-accent',
      mainImage: 'users.png',
      accentImage: 'users_active.png',
      tooltipId: 'users-tooltip',
      visible: (await apiClient.userPermissions()).data.crm_access
    },
    {
      id: 'codes',
      name: 'Коды',
      url: '/crm/code',
      imgId: 'codes-img',
      accentImgId: 'codes-img-accent',
      mainImage: 'hash.png',
      accentImage: 'hash_active.png',
      tooltipId: 'codes-tooltip',
      visible: (await apiClient.userPermissions()).data.crm_access && ((await apiClient.userPermissions()).data.own_codes_access || (await apiClient.userPermissions()).data.super_user)
    },
    {
      id: 'sales',
      name: 'Продажи',
      url: '/crm/profit',
      imgId: 'sales-img',
      accentImgId: 'sales-img-accent',
      mainImage: 'sales.png',
      accentImage: 'sales_active.png',
      tooltipId: 'sales-tooltip',
      visible: (await apiClient.userPermissions()).data.crm_access
    }
    // {
    //   id: 'export',
    //   name: 'Экспорт',
    //   url: '/export',
    //   visible: true
    // }
  ];
}
