import { apiClient } from '@/services/apiClient';
import toObject from '@/store/utils/toObject';

export default {
  namespaced: true,

  state: {
    data: {},
    ids: [],
    searchInput: '',
    isPending: false
  },

  getters: {
    getFilteredObjectsIds: state => {
      const { searchInput, data, ids } = state;
      if (searchInput) {
        return ids.filter(id => data[id].title.toLowerCase().includes(searchInput.toLowerCase()))
      }

      return ids
    },

    getObject: state => id => {
      return state.data[id];
    }
  },

  actions: {
    FETCH_OBJECTS: ({ commit, rootState }) => {
      return new Promise((resolve, reject) => {
        const companyId = rootState.companies.selectedId;
        if (companyId) {
          commit('SET_PENDING', true);
          apiClient.fetchObjects(companyId)
            .then(resp => {
              const items = resp.data
              commit('SET_OBJECTS', toObject(items, 'guid'));
              resolve(resp)
            })
          .catch(err => {
            reject(err)
          }).then(() => commit('SET_PENDING', false))
        } else {
          resolve()
        }
      })
    },

    SEARCH_OBJECTS: ({ commit }, input) => {
      commit('SET_SEARCH', input);
    }
  },

  mutations: {
    SET_OBJECTS: (state, { data, ids }) => {
      state.data = data;
      state.ids = ids;
    },

    SET_SEARCH: (state, input) => {
      state.searchInput = input;
    },

    SET_PENDING: (state, isPending) => {
      state.isPending = isPending
    }
  }
}
