<template>
  <div class="login-form">
    <Error>{{ error }}</Error>

    <form class="login-form__form" name="auth_form" @submit.prevent="login">
      <LoginFormInput
        placeholder="Email"
        type="email"
        name="email"
        required
        autofocus
        autocomplete="username"
        v-model="email"
      />
      <LoginFormInput
        class="second"
        type="password"
        placeholder="Пароль"
        minlength="6"
        required
        autocomplete="current-password"
        v-model="password"
      />
      <div class="btn-container">
        <LoginButton :class="{'login-form__submit_loading': loading}">
          <Loader v-if="loading" />
          <template v-else>
            Войти
          </template>
        </LoginButton>
      </div>
    </form>
    <div class="restore-container">
      <router-link
        class="restore"
        to="/restore-pass"
      >
        Восстановить пароль
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Error from '@/components/Error/Error';
import Loader from '@/components/Loader/Loader';
import LoginButton from '@/components/LoginButton/LoginButton.vue';
import LoginFormInput from '@/components/LoginFormInput/LoginFormInput.vue';

export default {
  data () {
    return {
      email: null,
      password: null,
      error: null
    }
  },

  components: {
    LoginFormInput,
    LoginButton, Error, Loader
  },

  computed: {
     ...mapState('auth', {
      loading: state => state.status === 'loading'
    })
  },

  methods: {
    ...mapActions('auth', [
      'AUTH_REQUEST'
    ]),

    login: function () {
      const { email, password, AUTH_REQUEST } = this;
      AUTH_REQUEST({ email, password }).then(() => {
        this.$router.push('/')
      }).catch(err => {
        this.error = err
      })
    }
  }
}
</script>

<style src="./login-form.scss" lang="scss"></style>
