<template>
  <div class="company-logo">
    <img
      class="company-logo__img"
      v-if="imgSrc"
      :src="imgSrc"
      alt="logo"
    />

    <svg-icon
      v-else
      class="company-logo__default"
      :width="small ? 14 : 24"
      :height="21"
      iconType="defaultCompany"
      viewBox="0 0 24 21"
    />
  </div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon/SvgIcon';

export default {
  props: {
    imgSrc: String,
    small: Boolean
  },

  components: {
    SvgIcon
  }
}
</script>

<style scoped lang="scss" src="./company-logo.scss"></style>
