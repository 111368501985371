<template>
  <div :style="iconStyle" class="container">
    <svg
      width="100%"
      :viewBox="viewBox"
      preserveAspectRatio="xMinYMin meet"
      role="presentation"
      class="svg"
    >
      <g :fill="iconColor" >
        <component :is="iconType" />
      </g>
    </svg>
  </div>
</template>

<script>
import icons from '@/components/Icons';

export default {
  name: 'SvgIcon',
  components: {
    ...icons
  },
  props: {
    iconType: {
      type: String,
      default: () => null
    },
    iconColor: {
      type: String,
      default: 'currentColor'
    },
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    viewBox: {
      type: String,
      default: '0 0 24 24'
    }
  },
  computed: {
    iconStyle () {
      const { width, height } = this;
      return {
        width: `${width}px`,
        height: `${height}px`
      };
    }
  }
};
</script>

<style lang="scss" scoped>
  .container {
    position: relative;
  }

  .svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>
