import createGUID from '@/store/utils/createGUID';

export default (categoryGUID) => ({
  title: 'Услуга',
  category: categoryGUID,
  unit_of_measure: 'кг.',
  cost_per_unit: 100,
  price_per_unit: 200,
  guid: createGUID()
});
