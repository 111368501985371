import errors from './errors';

export default data => {
  if (typeof (data) === 'string') {
    return 'Error'
  }

  const key = Object.keys(data)[0]
  if (key) {
    return errors[key]
  } else {
    return 'Error'
  }
}
