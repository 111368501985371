import { apiClient } from '@/services/apiClient';

const createDefaultState = () => ({
  users: [],
  codes: [],
  from: {
    email: null
  },
  profitByMonth: [],
  profitHistory: [],
  totalProfit: {
    total: 0,
    percentage: 0
  },
  usersLoaded: false,
  su: false,
  ownCodeAccess: false,
  codesLoaded: false,
  totalProfitLoaded: false,
  profitByMonthLoaded: false,
  profitHistoryLoaded: false,
  statistics: {
    active: 0,
    disabled: 0,
    new: 0
  },
  statisticsLoaded: false
})

export default {
  namespaced: true,

  state: createDefaultState(),

  actions: {
    CHANGE_CURRENT_USER: ({ commit }, payload) => {
      commit('SET_CURRENT_USER', payload.from);
    },
    FETCH_USERS: ({ commit }, payload) => {
      let params = {}
      if (payload !== undefined && payload !== null) {
        params = payload
      }
      return new Promise((resolve, reject) => {
        apiClient.crmUsers(params).then(resp => {
          commit('SET_USERS', resp.data);
        }).catch(err => {
          reject(err)
        }).then(() => commit('SET_USERS_LOADED'))
      })
    },
    FETCH_PERMISSIONS: ({ commit }) => {
      return new Promise((resolve, reject) => {
        apiClient.userPermissions().then(resp => {
          commit('SET_PERMISSIONS', resp.data);
        }).catch(err => {
          reject(err)
        })
      })
    },
    FETCH_CODES: ({ commit }, payload) => {
      let params = {}
      if (payload !== undefined && payload !== null) {
        params = payload
      }
      return new Promise((resolve, reject) => {
        apiClient.activationCodes(params).then(resp => {
          commit('SET_CODES', resp.data);
        }).catch(err => {
          reject(err)
        }).then(() => commit('SET_CODES_LOADED'))
      })
    },
    CREATE_USER: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        apiClient.crmAddUser(payload).then(resp => {
          resolve(resp)
        }).catch(err => {
          reject(err)
        })
      })
    },
    UPDATE_USER: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        apiClient.crmUpdateUser(payload.id, {
          status: payload.status,
          telegram: payload.telegram,
          comment: payload.comment,
          next_time: payload.nextTime
        }).then(resp => {
          resolve(resp)
        }).catch(err => {
          reject(err)
        })
      })
    },
    DELETE_USER: ({ commit }, id) => {
      return new Promise((resolve, reject) => {
        apiClient.crmDeleteUser(id).then(resp => {
          resolve(resp)
        }).catch(err => {
          reject(err)
        })
      })
    },
    CHANGE_USER: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        apiClient.crmUsers(payload).then(resp => {
          commit('SET_USERS', resp.data);
          commit('SET_CURRENT_USER', payload.from);
        }).catch(err => {
          reject(err)
        }).then(() => commit('SET_USERS_LOADED'))
      })
    },
    FETCH_TOTAL_PROFIT: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        apiClient.crmProfit().then(resp => {
          commit('SET_TOTAL_PROFIT', resp.data);
        }).catch(err => {
          reject(err)
        }).then(() => commit('SET_TOTAL_PROFIT_LOADED'))
      })
    },
    FETCH_PROFIT_BY_MONTH: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        apiClient.crmProfitByMonth().then(resp => {
          commit('SET_PROFIT_BY_MONTH', resp.data);
        }).catch(err => {
          reject(err)
        }).then(() => commit('SET_PROFIT_BY_MONTH_LOADED'))
      })
    },
    FETCH_PROFIT_HISTORY: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        apiClient.crmProfitHistory().then(resp => {
          commit('SET_PROFIT_HISTORY', resp.data);
        }).catch(err => {
          reject(err)
        }).then(() => commit('SET_PROFIT_HISTORY_LOADED'))
      })
    }
  },

  mutations: {
    SET_USERS: (state, items) => {
      state.users = items
      state.statistics = {
          active: 0,
          disabled: 0,
          new: 0
      }
      for (const u of state.users) {
        if (u.subscription_until === null) {
          state.statistics.new = state.statistics.new + 1
        } else {
          if (new Date(u.subscription_until) < new Date()) {
            state.statistics.disabled = state.statistics.disabled + 1
          } else {
            state.statistics.active = state.statistics.active + 1
          }
        }
      }
      state.statisticsLoaded = true
    },
    SET_USERS_LOADED: (state) => {
      state.usersLoaded = true
    },
    SET_PERMISSIONS: (state, data) => {
      state.su = data.super_user
      state.ownCodeAccess = data.own_codes_access
    },
    SET_CODES: (state, items) => {
      state.codes = items;
    },
    SET_CODES_LOADED: (state) => {
      state.codesLoaded = true
    },
    SET_TOTAL_PROFIT: (state, data) => {
      state.totalProfit.total = data.total;
      state.totalProfit.percentage = data.percentage;
    },
    SET_TOTAL_PROFIT_LOADED: (state) => {
      state.totalProfitLoaded = true
    },
    SET_PROFIT_BY_MONTH: (state, items) => {
      state.profitByMonth = items;
    },
    SET_PROFIT_BY_MONTH_LOADED: (state) => {
      state.profitByMonthLoaded = true
    },
    SET_PROFIT_HISTORY: (state, items) => {
      state.profitHistory = items;
    },
    SET_CURRENT_USER: (state, from) => {
      state.from = {
        email: from
      }
    },
    SET_PROFIT_HISTORY_LOADED: (state) => {
      state.profitHistoryLoaded = true
    }
  }
}
