<template>
  <div class="user-info">
    <p class="user-info__name">{{userInfo.user_title}}</p>
    <p class="user-info__email">{{userInfo.email}}</p>
  </div>
</template>

<script>
import { apiClient } from '@/services/apiClient';

export default {
  data () {
    return {
      userInfo: {
        email: '',
        user_title: ''
      }
    }
  },
  created () {
    this.getUserInfo()
  },
  methods: {
    getUserInfo: async function () {
      this.userInfo = (await apiClient.userRequisites()).data
    }
  }
}
</script>

<style scoped lang="scss">
  .user-info {
    margin: 12px 22px 0 22px;
    font-family: $montserrat;
    color: #59A9FF;
    letter-spacing: -0.5px;
    font-weight: 400;

    &__name {
      margin: 0;
      font-size: 14px;
      line-height: 21px;
    }

    &__email {
      margin: 0;
      font-size: 10px;
      line-height: 14px;
    }
  }
</style>
