<template>
    <div class="header__company-inner">
        <CompanyLogo
            :imgSrc="imgSrc"
        />
        <div class="header__company-title">
            {{ title }}
        </div>
        <div class="header__company-open"></div>
    </div>
</template>

<script>
import CompanyLogo from '@/components/CompanyLogoNew/CompanyLogo'

export default {
    props: {
        title: String,
        imgSrc: String
    },
    components: {
        CompanyLogo
    }
}
</script>
