import { apiClient } from '@/services/apiClient';
import toObject from '@/store/utils/toObject';
import createGUID from '@/store/utils/createGUID';
import createInitialService from '@/store/utils/createInitialService';

const createDefaultState = () => ({
  services: {
    data: {},
    ids: []
  },
  categories: {
    data: {},
    ids: []
  },
  isPending: false,
  searchInput: ''
})

export default {
  namespaced: true,

  state: createDefaultState(),

  getters: {
    getCategories: state => {
      const { searchInput, services, categories } = state;

      if (services.ids) {
        let result = [];

        result = categories.ids.filter(x => !categories.data[x].is_deleted).map(categoryId => {
          return {
            ...categories.data[categoryId],
            services: services.ids.filter(id => {
              const isInSearch = searchInput ? services.data[id].title.toLowerCase().includes(searchInput.toLowerCase()) : true;
              return !services.data[id].is_deleted && services.data[id].category === categoryId && isInSearch
            }).sort((a, b) => services.data[a].position - services.data[b].position)
              .map(id => services.data[id])
          }
        }).sort((a, b) => a.position - b.position)

        return result;
      } else {
        return []
      }
    },

    getExistedServicesByCategory: state => categoryGUID => {
      const { services: { ids, data } } = state;
      return ids.filter(x => data[x].category === categoryGUID && !data[x].is_deleted)
    },

    getMaxFromFields: () => (collection, field, categoryGUID) => {
      const { ids, data } = collection;
      return Math.max(...ids.filter(x => {
        const isDeleted = data[x].is_deleted;
        if (categoryGUID) {
          return !isDeleted && data[x].category === categoryGUID;
        }

        return !isDeleted;
      }).map(id => data[id][field]), -1) + 1;
    }
  },

  actions: {
    FETCH_ALL: ({ dispatch }) => {
      return Promise.all([
        dispatch('FETCH_SERVICES'),
        dispatch('FETCH_CATEGORIES')
      ])
    },

    FETCH_SERVICES: ({ commit, rootState }) => {
      return new Promise((resolve, reject) => {
        const companyId = rootState.companies.selectedId;
        if (companyId) {
          commit('SET_PENDING', true);
          apiClient.fetchServices(companyId)
            .then(resp => {
              const items = resp.data;
              commit('SET_SERVICES', toObject(items, 'guid'));
              resolve(resp)
            })
          .catch(err => {
            reject(err)
          }).then(() => commit('SET_PENDING', false))
        } else {
          resolve()
        }
      })
    },

    FETCH_CATEGORIES: ({ commit, rootState }) => {
      return new Promise((resolve, reject) => {
        const companyId = rootState.companies.selectedId;
        apiClient.fetchServicesCategories(companyId).then(resp => {
          const items = resp.data;
          commit('SET_CATEGORIES', toObject(items, 'guid'));
        }).catch(err => {
          reject(err)
        })
      })
    },

    SEARCH_SERVICES: ({ commit }, input) => {
      commit('SET_SEARCH', input);
    },

    SET_CATEGORIES_POSITION: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        const data = {
          sort_positions: payload.map(({ guid }, index) => ({ guid: guid, position: index }))
        }
        commit('SET_CATEGORIES_POSITION', payload);
        apiClient.setCategoriesPositions(data).catch(err => {
          reject(err)
        })
      })
    },

    CHANGE_CATEGORY: ({ state, commit, rootState, getters }, payload) => {
      return new Promise((resolve, reject) => {
        const companyId = rootState.companies.selectedId;
        apiClient.addServiceCategory(
          {
            title: payload,
            company: companyId,
            guid: createGUID()
          }
        ).then(resp => {
          const position = getters.getMaxFromFields(state.categories, 'position');
          apiClient.setCategoriesPositions({ sort_positions: [{ guid: resp.data.guid, position }] }).then(() => {
            commit('ADD_CATEGORY', { ...resp.data, position });
            resolve(resp.data.guid);
          }).catch(err => {
            reject(err)
          })
        }).catch(err => {
          reject(err)
        })
      })
    },

    RENAME_CATEGORY: ({ commit }, { title, guid }) => {
      return new Promise((resolve, reject) => {
        apiClient.editServiceCategory({ title, guid }).then(resp => {
          commit('RENAME_CATEGORY', { title, guid });
        }).catch(err => {
          reject(err)
        })
      })
    },

    REMOVE_CATEGORY: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        apiClient.removeServiceCategory(payload).then(resp => {
          commit('REMOVE_CATEGORY', payload);
        }).catch(err => {
          reject(err)
        })
      })
    },

    ADD_SERVICE: ({ dispatch, commit, state, getters }, { categoryGUID, upperGUID }) => {
      return new Promise((resolve, reject) => {
        const initialService = createInitialService(categoryGUID);
        if (upperGUID) {
          initialService.position = state.services.data[upperGUID].position + 1;
        } else {
          initialService.position = getters.getMaxFromFields(state.services, 'position', categoryGUID);
        }

        apiClient.addService(initialService).then(async resp => {
          commit('ADD_SERVICE', initialService);

          await dispatch('SET_SERVICES_POSITION2', getters.getExistedServicesByCategory(categoryGUID)
            .map(x => {
              let position = state.services.data[x].position;
              if (x !== initialService.guid && position >= initialService.position) {
                position++
              }
              return ({
                guid: x, position
              })
            }));

          resolve(resp.data.guid)
        }).catch(err => {
          reject(err)
        })
      })
    },

    SET_SERVICES_POSITION: ({ commit }, services) => {
      return new Promise((resolve, reject) => {
        const data = {
          sort_positions: services.map(({ guid }, index) => ({ guid, position: index }))
        }

        commit('SET_SERVICES_POSITION', services.map(({ guid }) => guid));
        apiClient.setServicesPositions(data).catch(err => {
          reject(err)
        })
      })
    },

    SET_SERVICES_POSITION2: ({ commit }, services) => {
      return new Promise((resolve, reject) => {
        const data = {
          sort_positions: services.map(({ guid, position }) => ({ guid, position }))
        }

        commit('SET_SERVICES_POSITION2', services);
        apiClient.setServicesPositions(data).then(() => resolve())
          .catch(err => {
            reject(err)
          })
      })
    },

    REMOVE_SERVICE: ({ commit }, id) => {
      return new Promise((resolve, reject) => {
        apiClient.removeService(id).then(resp => {
          commit('REMOVE_SERVICE', id);
        }).catch(err => {
          reject(err)
        })
      })
    },

    DUPLICATE_SERVICE: async ({ commit, dispatch, state, getters }, id) => {
      const fromService = state.services.data[id];
      const service = { ...fromService, position: fromService.position + 1 };
      service.guid = createGUID();
      await apiClient.addService(service);
      commit('ADD_SERVICE', service);
      await dispatch('SET_SERVICES_POSITION2', getters.getExistedServicesByCategory(service.category)
        .map(x => {
          let position = state.services.data[x].position;
          if (x !== service.guid && position >= service.position) {
            position++
          }
          return ({
            guid: x, position
          })
        }));
    },

    EDIT_SERVICE: ({ commit, dispatch, state, getters }, service) => {
      return new Promise((resolve, reject) => {
        apiClient.editService(service).then(async resp => {
          const isSwitchCategory = service.category !== state.services.data[service.guid].category;
          commit('EDIT_SERVICE', service);

          if (isSwitchCategory) {
            const existedServicesByCategory = getters.getExistedServicesByCategory(service.category);
            await dispatch('SET_SERVICES_POSITION2', existedServicesByCategory
              .map(x => {
                let position = state.services.data[x].position;
                if (x !== service.guid && position >= service.position) {
                  position++
                }
                return ({
                  guid: x, position
                })
              }));
          }
        }).catch(err => {
          reject(err)
        })
      })
    },

    DELETE_PRICE: async ({ commit, rootState }) => {
      const companyId = rootState.companies.selectedId;
      await apiClient.deleteAll(companyId);
      commit('DELETE_PRICE');
    }
  },

  mutations: {
    SET_SERVICES: (state, items) => {
      state.services = items;
    },

    SET_CATEGORIES: (state, items) => {
      state.categories = items
    },

    SET_SEARCH: (state, input) => {
      state.searchInput = input;
    },

    SET_PENDING: (state, isPending) => {
      state.isPending = isPending
    },

    SET_CATEGORIES_POSITION: (state, payload) => {
      payload.forEach((x, index) => {
        state.categories.data[x.guid].position = index;
      })
    },

    ADD_CATEGORY: (state, category) => {
      const { data, ids } = state.categories;

      ids.push(category.guid);
      data[category.guid] = { ...category };
    },

    REMOVE_CATEGORY: (state, categoryGUID) => {
      const { categories } = state;
      const index = categories.ids.findIndex(x => x === categoryGUID);
      categories.ids = [...categories.ids.slice(0, index), ...categories.ids.slice(index + 1)];
      categories.data[categoryGUID] = { ...categories.data[categoryGUID], is_deleted: true };
    },

    ADD_SERVICE: (state, service) => {
      state.services.ids.push(service.guid);
      state.services.data[service.guid] = { ...service };
    },

    SET_SERVICES_POSITION: (state, payload) => {
      payload.forEach((x, index) => {
        state.services.data[x].position = index;
      })
    },

    SET_SERVICES_POSITION2: (state, payload) => {
      payload.forEach(({ guid, position }) => {
        state.services.data[guid].position = position;
      })
    },

    REMOVE_SERVICE: (state, id) => {
      const { services } = state;
      const index = state.services.ids.findIndex(x => x === id);
      state.services.ids = [...services.ids.slice(0, index), ...services.ids.slice(index + 1)];
      state.services.data[id] = { ...state.services.data[id], is_deleted: true };
    },

    EDIT_SERVICE: (state, service) => {
      state.services.data = { ...state.services.data, [service.guid]: { ...service } };
    },

    RENAME_CATEGORY: (state, { title, guid }) => {
      state.categories.data[guid].title = title;
    },

    DELETE_PRICE: (state) => {
      Object.assign(state, createDefaultState());
    }
  }
}
