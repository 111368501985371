import LoginPage from '@/views/LoginPageNew/LoginPage.vue';
import store from '@/store';
import { apiClient } from '@/services/apiClient';

const ifNotAuthenticated = (to, from, next) => {
  const isAuthenticated = store.getters['auth/isAuthenticated']
  if (!isAuthenticated) {
    next()
    return
  }
  next('/')
}

const ifAuthenticated = (to, from, next) => {
  const isAuthenticated = store.getters['auth/isAuthenticated']
  if (isAuthenticated) {
    const { isLoaded } = store.state.companies
    if (!isLoaded) {
      store.dispatch('companies/FETCH_COMPANIES').then(() => next())
    } else {
      next()
    }
    return
  }
  next('/login')
}

const ifCRMAccess = async (to, from, next) => {
  const isAuthenticated = store.getters['auth/isAuthenticated']
  if (isAuthenticated) {
    const hasCRMAccess = (await apiClient.userPermissions()).data.crm_access
    if (hasCRMAccess) {
      const { isLoaded } = store.state.companies
      if (!isLoaded) {
        store.dispatch('companies/FETCH_COMPANIES').then(() => next())
      } else {
        next()
      }

      return
    }
  }
  next('/login')
}

const ifCRMCodesAccess = async (to, from, next) => {
  const isAuthenticated = store.getters['auth/isAuthenticated']
  if (isAuthenticated) {
    const hasCRMAccess = (await apiClient.userPermissions()).data.crm_access
    const hasCRMCodesAccess = (await apiClient.userPermissions()).data.own_codes_access
    const IS_SU = (await apiClient.userPermissions()).data.super_user
    if (hasCRMAccess && (hasCRMCodesAccess || IS_SU)) {
      const { isLoaded } = store.state.companies
      if (!isLoaded) {
        store.dispatch('companies/FETCH_COMPANIES').then(() => next())
      } else {
        next()
      }

      return
    }
  }
  next('/login')
}

const ifAnalyticsAccess = async (to, from, next) => {
  const isAuthenticated = store.getters['auth/isAuthenticated']
  if (isAuthenticated) {
    const hasAnalyticsAccess = (await apiClient.userPermissions()).data.analytics_access
    if (hasAnalyticsAccess) {
      const { isLoaded } = store.state.companies
      if (!isLoaded) {
        store.dispatch('companies/FETCH_COMPANIES').then(() => next())
      } else {
        next()
      }

      return
    }
  }
  next('/login')
}

export default [
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
    meta: {
      title: 'Вход'
    },
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/confirm-code',
    name: 'confirm-code',
    component: () => import(/* webpackChunkName: "confirm-code" */ '../views/ConfirmPage/ConfirmPage.vue'),
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/restore-pass',
    name: 'restore-pass',
    component: () => import(/* webpackChunkName: "restore-pass" */ '../views/RestorePage/RestorePage.vue'),
    beforeEnter: ifNotAuthenticated
  },
  // {
  //   path: '/objects',
  //   name: 'objects',
  //   component: () => import(/* webpackChunkName: "objects" */ '../views/ObjectsPage/ObjectsPage.vue'),
  //   meta: {
  //     title: 'Объекты'
  //   },
  //   beforeEnter: ifAuthenticated
  // },
  // {
  //   path: '/objects/:id',
  //   name: 'object',
  //   component: () => import(/* webpackChunkName: "object" */ '../views/ObjectPage/ObjectPage.vue'),
  //   beforeEnter: ifAuthenticated,
  //   props: true
  // },
  // {
  //   path: '/objects/create',
  //   name: 'objects-create',
  //   component: () => import(/* webpackChunkName: "object-create" */ '../views/ObjectCreatePage/ObjectCreatePage.vue'),
  //   meta: {
  //     title: 'Новый объект'
  //   },
  //   beforeEnter: ifAuthenticated
  // },
  // {
  //   path: '/transactions',
  //   name: 'transactions',
  //   component: () => import(/* webpackChunkName: "transactions" */ '../views/TransactionsPage/TransactionsPage.vue'),
  //   meta: {
  //     title: 'Транзакции'
  //   },
  //   beforeEnter: ifAuthenticated
  // },
  // {
  //   path: '/contractors',
  //   name: 'contractors',
  //   component: () => import(/* webpackChunkName: "contractors" */ '../views/ContractorsPage/ContractorsPage.vue'),
  //   meta: {
  //     title: 'Контрагенты'
  //   },
  //   beforeEnter: ifAuthenticated
  // },
  // {
  //   path: '/contractors/:id',
  //   name: 'contractor',
  //   component: () => import(/* webpackChunkName: "contractor" */ '../views/ContractorPage/ContractorPage.vue'),
  //   beforeEnter: ifAuthenticated,
  //   props: true
  // },
  {
    path: '/services',
    name: 'services',
    component: () => import(/* webpackChunkName: "services" */ '../views/ServicesPageNew/ServicesPage.vue'),
    meta: {
      title: 'Прайс-лист'
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/subscription',
    name: 'subscription',
    component: () => import(/* webpackChunkName: "subscription" */ '../views/SubscriptionPageUpdated/SubscriptionNewPage.vue'),
    meta: {
      title: 'Подписка'
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/price',
    name: 'price',
    component: () => import(/* webpackChunkName: "price" */ '../views/PriceBuyPageNew/PriceBuyPage.vue'),
    meta: {
      title: 'Прайс-лист'
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/crm/user',
    name: 'crmUser',
    component: () => import(/* webpackChunkName: "users" */ '../views/UsersListPageUpdated/UsersListPage.vue'),
    meta: {
      title: 'Пользователи'
    },
    beforeEnter: ifCRMAccess
  },
  {
    path: '/crm/code',
    name: 'crmCode',
    component: () => import(/* webpackChunkName: "codes" */ '../views/CodesListPageUpdated/CodesListPage.vue'),
    meta: {
      title: 'Коды'
    },
    beforeEnter: ifCRMCodesAccess
  },
  {
    path: '/crm/profit',
    name: 'crmProfit',
    component: () => import(/* webpackChunkName: "profit" */ '../views/ProfitPageUpdated/ProfitPage.vue'),
    meta: {
      title: 'Продажи'
    },
    beforeEnter: ifCRMAccess
  },
  {
    path: '/analytics',
    name: 'analytics',
    component: () => import(/* webpackChunkName: "analytics" */ '../views/AnalyticsPageNew/AnalyticsPage.vue'),
    meta: {
      title: 'Аналитика'
    },
    beforeEnter: ifAnalyticsAccess
  },
  {
    path: '/export',
    name: 'export',
    component: () => import(/* webpackChunkName: "export" */ '../views/ExportPageNew/ExportPage.vue'),
    meta: {
      title: 'Экспорт'
    },
    beforeEnter: ifAuthenticated
  },
  // {
  //   path: '/preliminary',
  //   name: 'preliminary',
  //   component: () => import(/* webpackChunkName: "preliminary" */ '../views/PreliminaryPage/PreliminaryPage.vue'),
  //   meta: {
  //     title: 'Предварительный расчет'
  //   },
  //   beforeEnter: ifAuthenticated
  // },
  {
    path: '/',
    name: 'home',
    redirect: '/services'
  },
  {
    path: '*',
    redirect: '/'
  }
]
