<template>
  <div class="app-info">
    <div class="app-info__item">
      <img class="app-info__icon" :src="require('./assets/tg.png')" alt="">
      <a class="app-info__item-link" href="https://t.me/app_101" target="_blank">Полезное</a>
    </div>
    <div class="app-info__item">
      <img class="app-info__icon" :src="require('./assets/chat.png')" alt="">
      <a class="app-info__item-link" href="https://t.me/TechSupport101Bot" target="_blank">Чат</a>
    </div>
    <div class="app-info__item">
      <img class="app-info__icon" :src="require('./assets/info.png')" alt="">
      <a class="app-info__item-link" :href="getTutorialLink()" target="_blank">Руководство</a>
    </div>
    <div class="app-info__item">
      <img class="app-info__icon" :src="require('./assets/hand.png')" alt="">
      Документы
    </div>
    <div class="app-info__sub-item-list">
      <a class="app-info__sub-item app-info__item-link" href="https://101-app.com/privacy?utm_source=101app&utm_medium=web_version&utm_campaign=dashboard" target="_blank">
        Политика конфиденциальности
      </a>
      <a class="app-info__sub-item app-info__item-link" href="https://101-app.com/legacy?utm_source=101app&utm_medium=web&utm_campaign=dashboard" target="_blank">
        Лицензионное соглашение
      </a>
      <a class="app-info__sub-item app-info__item-link" href="https://101-app.com/offer?utm_source=101app&utm_medium=web&utm_campaign=dashboard" target="_blank">
        Договор оферты
      </a>
    </div>
  </div>
</template>

<script>
import { apiClient } from '@/services/apiClient';

export default {
  data () {
    return {
      userInfo: {
        email: '',
        user_title: ''
      }
    }
  },
  created () {
    this.getUserInfo()
  },
  methods: {
    getUserInfo: async function () {
      this.userInfo = (await apiClient.userRequisites()).data
    },
    getTutorialLink: function () {
      return 'https://101-app.com/faq?utm_source=101app&utm_medium=web&utm_campaign=dashboard#!/tfeeds/229179393101/c/%D0%A0%D1%83%D0%BA%D0%BE%D0%B2%D0%BE%D0%B4%D1%81%D1%82%D0%B2%D0%BE%20%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8F%20%D0%B4%D0%BB%D1%8F%20%D0%97%D0%B0%D0%BA%D0%B0%D0%B7%D1%87%D0%B8%D0%BA%D0%B0'
    }
  }
}
</script>

<style scoped lang="scss">
  .app-info {
    margin: 10px 16px 36px 16px;
    font-family: $montserrat;
    color: #8A8A8F;
    letter-spacing: -0.5px;
    font-weight: 400;

    &__item {
      display: flex;
      align-items: center;
      font-size: 14px;
    }
    &__icon {
      width: 44px;
      height: 44px;
    }
    &__sub-item-list {
      padding-left: 44px;
    }
    &__sub-item {
      display: block;
      margin-bottom: 8px;
      font-size: 12px;

      &:last-child {
        margin-bottom: 0;
      }
    }
    &__item-link {
      color: #8A8A8F;
      text-decoration: none;
    }
  }
</style>
