<template>
  <ul v-if="links.length > 0" class="nav">
    <template v-for="(link, index) in links">
      <li v-if="link.visible" :key="index" class="nav__item">
        <router-link class="nav__link" :to="link.url">
        <span>
          {{ link.name }}
        </span>
        <span v-if="link.id in amount">
          {{ ` ${ amount[link.id].isPending ? 'Загрузка' : amount[link.id].value }` }}
        </span>
        </router-link>
      </li>
    </template>
  </ul>
</template>

<script>
import getLinks from './links';
import { mapActions, mapState } from 'vuex';

export default {
  data: function () {
    return {
      links: []
    };
  },
  created () {
    this.FETCH_USERS();
    getLinks().then(data => {
      this.links = data;
    })
  },
  computed: {
    ...mapState({
      amount: state => {
        return {
          objects: {
            value: state.objects.ids.length || 0,
            isPending: state.objects.isPending
          },
          contractors: {
            value: state.contractors.contractors.ids.length || 0,
            isPending: state.contractors.isPending
          },
          transactions: {
            value: state.transactions.ids.length || 0,
            isPending: state.transactions.isPending
          },
          services: {
            value: (() => {
              const ids = state.services.services.ids;
              const data = state.services.services.data;
              return ids.filter(id => !data[id].is_deleted).length
            })(),
            isPending: state.services.isPending
          },
          crmUser: {
            value: (() => {
              const ids = state.user.users;
              return ids.length
            })(),
            isPending: !state.user.usersLoaded
          }
        }
      }
    })
  },
  methods: {
    ...mapActions('user', [
      'FETCH_USERS'
    ])
  }
}
</script>

<style lang="scss" src="./nav.scss"></style>
