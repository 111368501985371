import { apiClient } from '@/services/apiClient';
import toObject from '@/store/utils/toObject';

const initialState = {
  data: {},
  ids: [],
  selectedId: null,
  isLoaded: false,
  expenseCategories: {
    data: {},
    ids: []
  }
};

export default {
  namespaced: true,

  state: initialState,

  getters: {
    getCurrentUserId: ({ data, selectedId }) => {
      return data[selectedId].user_contractor_id;
    }
  },

  actions: {
    FETCH_COMPANIES: ({ commit, dispatch }) => {
      return new Promise((resolve, reject) => {
        apiClient.fetchCompanies()
          .then(resp => {
            const items = resp.data
            commit('SET_COMPANIES', toObject(items, 'guid'))

            if (items.length) {
              const guid = localStorage.getItem('selected-company') || items[0].guid;
              dispatch('SELECT_COMPANY', guid).then(resolve());
            } else {
              resolve();
            }
          })
        .catch(err => {
          reject(err)
        })
      })
    },

    FETCH_EXPENSE_CATEGORIES: ({ state, commit }) => {
      return new Promise((resolve, reject) => { // The Promise used for router redirect in login
        apiClient.fetchExpenseCategories(state.selectedId)
          .then(resp => {
            const items = resp.data
            commit('SET_EXPENSE_CATEGORIES', toObject(items, 'guid'))
            resolve();
          })
        .catch(err => {
          reject(err)
        })
      })
    },

    SELECT_COMPANY: ({ commit, dispatch }, id) => {
      return new Promise((resolve, reject) => {
        commit('SET_SELECTED_COMPANY', id);
        localStorage.setItem('selected-company', id)

        const requests = [
          dispatch('FETCH_EXPENSE_CATEGORIES'),
          dispatch('objects/FETCH_OBJECTS', null, { root: true }),
          // dispatch('transactions/FETCH_TRANSACTIONS', null, { root: true }),
          dispatch('services/FETCH_ALL', null, { root: true }),
          dispatch('contractors/FETCH_ALL', null, { root: true })
        ]
        Promise.all(requests).then(resolve())
      })
    }
  },

  mutations: {
    SET_COMPANIES: (state, { data, ids }) => {
      state.data = data;
      state.ids = ids;
      state.isLoaded = true
    },

    SET_EXPENSE_CATEGORIES: (state, { data, ids }) => {
      state.expenseCategories.data = data;
      state.expenseCategories.ids = ids;
    },

    SET_SELECTED_COMPANY: (state, id) => {
      state.selectedId = id
    },

    RESET: (state) => {
      state.isLoaded = false;
    }
  }
}
