import { apiClient } from '@/services/apiClient';
import instance from '@/services/instance';
import getErrorMessage from './utils/getErrorMessage';

const addAuthHeader = (token) => { instance.defaults.headers.common.Authorization = `JWT ${token}` };
const token = localStorage.getItem('user-token')
if (token) {
  addAuthHeader(token)
}

export default {
  namespaced: true,

  state: {
    token: localStorage.getItem('user-token') || '',
    status: ''
  },

  getters: {
    isAuthenticated: state => !!state.token,
    authStatus: state => state.status
  },

  actions: {
    AUTH_REQUEST: ({ commit, dispatch }, user) => {
      return new Promise((resolve, reject) => { // The Promise used for router redirect in login
        commit('AUTH_REQUEST')
        apiClient.authRequest(user)
          .then(resp => {
            const token = resp.data.token
            localStorage.setItem('user-token', token) // store the token in localstorage
            addAuthHeader(token);
            commit('AUTH_SUCCESS', token)
            resolve(resp)
          })
        .catch(err => {
          commit('AUTH_ERROR', err)
          localStorage.removeItem('user-token') // if the request fails, remove any possible user token if possible
          reject(getErrorMessage(err.response.data))
        })
      })
    },

    AUTH_LOGOUT: ({ commit, dispatch }) => {
      return new Promise((resolve, reject) => {
        commit('AUTH_LOGOUT')
        commit('companies/RESET', null, { root: true })
        localStorage.removeItem('user-token') // clear your user's token from localstorage
        localStorage.removeItem('selected-company');
        // remove default header
        delete instance.defaults.headers.common.Authorization
        resolve()
      })
    },

    REG_REQUEST: ({ commit, dispatch }, data) => {
      return new Promise((resolve, reject) => {
        commit('REG_REQUEST')
        apiClient.regRequest(data).then(resp => {
          commit('REG_SUCCESS');
          resolve()
        }).catch(err => {
          commit('REG_ERROR');
          reject(getErrorMessage(err.response.data))
        })
      })
    },

    CODE_REQUEST: ({ commit, dispatch }, data) => {
      const { email, code } = data;
      return new Promise((resolve, reject) => {
        commit('CODE_REQUEST')
        apiClient.codeRequest({ confirmation_key: code, email }).then(resp => {
          commit('CODE_SUCCESS');
          resolve()
        }).catch(err => {
          commit('CODE_ERROR');
          reject(getErrorMessage(err.response.data))
        })
      })
    },

    RESTORE_EMAIL: ({ commit, dispatch }, email) => {
      return new Promise((resolve, reject) => {
        apiClient.restoreEmail(email).then(resp => {
          resolve()
        }).catch(err => {
          reject(getErrorMessage(err.response.data))
        })
      })
    },

    RESTORE_CODE: ({ commit, dispatch }, data) => {
      const { email, code } = data;
      return new Promise((resolve, reject) => {
        apiClient.restoreCode({ confirmation_key: code, email }).then(resp => {
          commit('RESTORE_CODE_SUCCESS', code);
          resolve()
        }).catch(err => {
          reject(getErrorMessage(err.response.data))
        })
      })
    },

    SET_PASS: ({ commit, dispatch, state }, data) => {
      return new Promise((resolve, reject) => {
        apiClient.restorePass({ ...data, confirmation_key: state.confirmation_key_for_restore }).then(resp => {
          commit('PASS_SUCCESS');
          resolve()
        }).catch(err => {
          commit('PASS_ERROR');
          reject(getErrorMessage(err.response.data))
        })
      })
    }
  },

  mutations: {
    AUTH_REQUEST: (state) => {
      state.status = 'loading'
    },
    AUTH_SUCCESS: (state, token) => {
      state.status = 'success'
      state.token = token
    },
    AUTH_ERROR: (state) => {
      state.status = 'error'
    },
    AUTH_LOGOUT: (state) => {
      state.token = ''
      state.status = ''
    },

    REG_REQUEST: (state) => {
      state.status = 'loading'
    },
    REG_SUCCESS: (state) => {
      state.status = 'success'
    },
    REG_ERROR: (state) => {
      state.status = 'error'
    },

    CODE_REQUEST: (state) => {
      state.status = 'loading'
    },
    CODE_SUCCESS: (state) => {
      state.status = 'success'
    },
    CODE_ERROR: (state) => {
      state.status = 'error'
    },

    RESTORE_CODE_SUCCESS: (state, confirmation_key) => {
      state.confirmation_key_for_restore = confirmation_key
    },
    PASS_SUCCESS: (state) => {
      state.status = 'success'
    },
    PASS_ERROR: (state) => {
      state.status = 'error'
    }
  }
}
