<template>
  <div class="company-logo-inner">
    <img
      class="company-logo__img"
      v-if="imgSrc"
      :title="imgTitle"
      :src="imgSrc"
      alt="logo"
    />

    <svg-icon
      v-else
      class="company-logo-inner__default"
      :title="imgTitle"
      :width="10"
      :height="10"
      iconType="defaultCompanyNew"
      viewBox="0 0 24 24"
    />
  </div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon/SvgIcon';

export default {
  data () {
    return {
      src: './assets/avatar.png'
    }
  },
  props: {
    imgSrc: String,
    imgTitle: String,
    small: Boolean
  },

  components: {
    SvgIcon
  }
}
</script>

<style scoped lang="scss" src="./company-logo.scss"></style>
