export default {
  1000: 'Пользователь не активен',
  1001: 'Email уже зарегистрирован',
  1002: 'Неправильный email или пароль',
  1003: 'Successfully created contractor for existing user',
  1004: 'Contractor already in this company',
  1005: 'Старый пароль неверен',
  1006: 'Пользователя с таким email не существует',
  1007: 'Неправильный код подтверждения',
  1008: 'Contractor (not activated user) in company',
  1009: 'Confirmation key is correct',
  1010: 'Некорректный email',
  1011: 'Некорректное имя компании'
}
