<template>
  <div class="side">
    <div>
      <div class="side__companies">
        <Companies />
        <UserInfo />
      </div>

      <nav class="side__nav">
        <Nav />
      </nav>
    </div>

    <div>
      <AppInfo />
    </div>
  </div>
</template>

<script>
import Nav from '@/components/Nav/Nav';
import Companies from '@/components/Companies/Companies';
import UserInfo from '@/components/UserInfo/UserInfo.vue';
import AppInfo from '@/components/AppInfo/AppInfo.vue';

export default {
  components: {
    UserInfo,
    Nav, Companies, AppInfo
  },
  props: {
    title: String
  }
}
</script>

<style lang="scss" src="./side.scss"></style>
