import { apiClient } from '@/services/apiClient';

const createDefaultState = () => ({
  companyExpenseData: [],
  companyProfitData: []
})

export default {
  namespaced: true,

  state: createDefaultState(),

  actions: {
    FETCH_COMPANY_EXPENSE_DATA: ({ commit }, guid) => {
      return new Promise((resolve, reject) => {
        apiClient.fetchCompanyExpenseData(guid).then(resp => {
          commit('SET_COMPANY_EXPENSE_DATA', resp.data);
        }).catch(err => {
          reject(err)
        })
      })
    },
    FETCH_COMPANY_PROFIT_DATA: ({ commit }, guid) => {
      return new Promise((resolve, reject) => {
        apiClient.fetchCompanyProfitData(guid).then(resp => {
          commit('SET_COMPANY_PROFIT_DATA', resp.data);
        }).catch(err => {
          reject(err)
        })
      })
    }
  },

  mutations: {
    SET_COMPANY_EXPENSE_DATA: (state, data) => {
      state.companyExpenseData = data;
    },
    SET_COMPANY_PROFIT_DATA: (state, data) => {
      state.companyProfitData = data;
    }
  }
}
