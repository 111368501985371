import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes';
import store from '@/store';

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach((to, from) => {
  const isMenu = store.state.common.isMenu || false
  if (isMenu) {
    store.dispatch('common/toggleMenu');
    document.body.style.overflow = 'auto';
  }
})

export default router
