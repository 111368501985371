<template>
  <Modal name="logout-modal">
    <div class="modal-holder">
      <div class="heading">Вы действительно хотите выйти из аккаунта?</div>
      <div class="buttons">
        <button class="transparent" @click="$emit('close')">Отменить</button>
        <button @click="$emit('logout')">Выйти</button>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/HeaderModal/Modal';
import { mapActions } from 'vuex';
import CrmUserStatus from '@/utils/crmUserStatus';
import formatDateInput from '@/utils/formatDateInput';

export default {
  components: {
    Modal
  },
  data () {
    return {
      email: null,
      status: '',
      telegram: null,
      comment: null,
      nextTime: this.getDefaultNextTime(),
      error: null,
      statuses: []
    }
  },
  created () {
    CrmUserStatus.list().then(data => {
      this.statuses = data;
    });
  },
  methods: {
    ...mapActions('user', [
      'CREATE_USER'
    ]),
    handleSubmit: function () {
      this.CREATE_USER({
        email: this.email,
        status: this.status,
        telegram: this.telegram,
        comment: this.comment,
        next_time: this.nextTime
      }).then(() => {
        this.$emit('onAddUser');
        this.email = null;
        this.status = '';
        this.telegram = null;
        this.comment = null;
        this.nextTime = this.getDefaultNextTime();
        this.error = null;
      }).catch(err => {
        if (err.response.status === 400) {
          this.error = err.response.data[0]
        } else {
          this.error = 'Произошла ошибка'
        }
      })
    },
    getDefaultNextTime: function () {
      const dt = new Date();
      dt.setMonth(dt.getMonth() + 1);

      return formatDateInput(dt);
    }
  }
}
</script>

<style src="./logout-modal.scss" lang="scss" scoped>

</style>
