<template>
  <div v-if="canView()" class="goto-subscription" @click="$router.push('/subscription')">
    Подписка <span class="goto-subscription_bold">PRO</span>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  props: {
    isAuthenticated: Boolean
  },
  created () {
    if (this.isAuthenticated) {
      this.FETCH_SUBSCRIPTIONS()
    }
  },
  computed: {
    ...mapState({
      subscriptionsLoaded: state => state.subscription.subscriptionsLoaded
    }),
    ...mapGetters('subscription', {
      subscription: 'getActiveSubscription'
    })
  },
  methods: {
    ...mapActions('subscription', [
      'FETCH_SUBSCRIPTIONS'
    ]),
    canView: function () {
      return this.isAuthenticated &&
        this.$route.name !== 'subscription' &&
        this.subscriptionsLoaded &&
        this.subscription === null
    }
  }
}
</script>

<style scoped lang="scss">
  .goto-subscription {
    width: 195px;
    height: 46px;
    margin: 7px 10px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 14px;
    background-color: #3478F6;
    font-family: $montserrat;
    font-style: normal;
    font-weight: 600;
    color: #fff;
    text-decoration: none;
    cursor: pointer;

    @media (min-width: $tablet) {
      display: none;
    }

    &_bold {
      padding-left: 3px;
      font-weight: 900;
    }
  }
</style>
