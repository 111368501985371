export const expense = 'expense';
export const payment = 'payment';
export const income = 'income';
export const dividend = 'dividend';

export default [expense, payment, income, dividend];

export const typeTitles = {
  expense: 'Счет',
  payment: 'Перевод',
  income: 'Доход',
  dividend: 'Дивиденды'
}
