import { apiClient } from '@/services/apiClient';

export default async function getLinks () {
  return [
    // {
    //   id: 'objects',
    //   name: 'Объекты',
    //   url: '/objects'
    // },
    // {
    //   id: 'transactions',
    //   name: 'События',
    //   url: '/transactions'
    // },
    // {
    //   id: 'contractors',
    //   name: 'Контрагенты',
    //   url: '/contractors'
    // },
    {
      id: 'subscriptions',
      name: 'Подписка',
      url: '/subscription',
      visible: true
    },
    {
      id: 'price',
      name: 'Прайс-лист от 101',
      url: '/price',
      visible: true
    },
    {
      id: 'crmUser',
      name: 'Пользователи',
      url: '/crm/user',
      visible: (await apiClient.userPermissions()).data.crm_access
    },
    {
      id: 'crmCode',
      name: 'Коды',
      url: '/crm/code',
      visible: (await apiClient.userPermissions()).data.crm_access && ((await apiClient.userPermissions()).data.own_codes_access || (await apiClient.userPermissions()).data.super_user)
    },
    {
      id: 'crmProfit',
      name: 'Продажи',
      url: '/crm/profit',
      visible: (await apiClient.userPermissions()).data.crm_access
    },
    {
      id: 'analytics',
      name: 'Аналитика',
      url: '/analytics',
      visible: (await apiClient.userPermissions()).data.analytics_access
    },
    {
      id: 'export',
      name: 'Экспорт',
      url: '/export',
      visible: true
    }
  ];
}
