import menuIcon from './menuIcon';
import logo from './logo';
import plus from './plus';
import dots from './dots';
import trash from './trash';
import duplicate from './duplicate';
import defaultCompany from './defaultCompany';
import defaultCompanyNew from './defaultCompanyNew';
import importIcon from './importIcon';
import exportIcon from './exportIcon';

export default { menuIcon, logo, plus, dots, trash, duplicate, defaultCompany, importIcon, exportIcon, defaultCompanyNew };
